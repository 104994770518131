import React from "react";
import { Button, Modal } from "react-bootstrap";
import selector from "./images/icons/selector.png";
import "./App.css";
import filter from "./images/icons/filter.png";
import { Col, Row } from "react-bootstrap"; 

/**
 * ライフスタイルテーブル描画クラス
 */
class LifeStyleTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchModalIsOpen: false,
      selectedId: 1,
      selectedModalId: 1,
      filterModalIsOpen: false,
      responseLifestyle: "",
      requestLifestyle: "",
      displayLifestyle: "",
      displayFutureLifestyle: "",
      displayAllZeroLifestyle: "",
      modalDisplayLifestyle: "",
      prevLifestyle: "",
    };
    this.stateChange = this.stateChange.bind(this);
    this.allCheck = this.allCheck.bind(this);
    this.allUnCheck = this.allUnCheck.bind(this);
    this.filterDefaultChecked = this.filterDefaultChecked.bind(this);
    this.displaySwitchDefaultChecked = this.displaySwitchDefaultChecked.bind(this);
    this.selectedState = this.selectedState.bind(this);
    this.setData = this.setData.bind(this);
    this.lifestyleCheckboxGenerator = this.lifestyleCheckboxGenerator.bind(this);
    this.lifestyleTableGenerator = this.lifestyleTableGenerator.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.lifestyleSwitchGenerator = this.lifestyleSwitchGenerator.bind(this);
    this.openFilterModal = this.openFilterModal.bind(this);
    this.openSwitchModal = this.openSwitchModal.bind(this);
    this.closeFilterModal = this.closeFilterModal.bind(this);
    this.closeSwitchModal = this.closeSwitchModal.bind(this);
    this.modalNameSwitcher = this.modalNameSwitcher.bind(this);
    this.allUnCheckSwitchbox = this.allUnCheckSwitchbox.bind(this);
  }

  /**
   * (React組み込み関数)絞り込み条件の変更有無が分かる
   * ※Navi.jsにshouldComponentUpdate()の公式URLを記載
   * @param {Array} nextProps - 次に更新される絞り込み結果
   * @return {boolean} 変更有無
   */
  shouldComponentUpdate(nextProps) {
    return !(this.state.requestLifestyle === nextProps.requestLifestyle);
  }

  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {Array} lifestyle_list - 絞り込みのリクエスト
   * @param {Array} re_lifestyle_list - 絞り込みのレスポンス
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   * @param {Array} displayResponseList - 画面表示させる15項目のレスポンスを格納
   * @param {number} count - 画面表示させる15項目カウント用変数
   */
  UNSAFE_componentWillMount(){
    let lifestyle_list = this.props.requestLifestyle;
    let re_lifestyle_list = this.props.responseLifestyle;
    let modal_display_list = JSON.parse(JSON.stringify(this.props.responseLifestyle));
    //レスポンスpropsをorder順にソート
    re_lifestyle_list.sort((a, b) => {
      if(a.lifestyle_order < b.lifestyle_order) return -1;
      if(a.lifestyle_order > b.lifestyle_order) return 1;
      return 0;
    });
    //モーダル表示用にレスポンスpropsをorder順にソート
    modal_display_list.sort((a, b) => {
      if(a.lifestyle_order < b.lifestyle_order) return -1;
      if(a.lifestyle_order > b.lifestyle_order) return 1;
      return 0;
    });
    //モーダル表示用にレスポンスpropsをさらにidで並べ替え
    for(let i = 0; i < modal_display_list.length; i++){   
      modal_display_list[i].lifestyle_contents_list.sort((a, b) => {    
        if(parseInt(a.lifestyle_contents_id) > parseInt(b.lifestyle_contents_id)) return 1;
        if(parseInt(a.lifestyle_contents_id) < parseInt(b.lifestyle_contents_id)) return -1;
        return 0;
      });
    }
    //初期描画時のみidでソート
    //特徴係数が0かどうかで判断
    let defaultSort = true;
    for(let i = 0; i < re_lifestyle_list[0].lifestyle_contents_list.length; i++){
      if(re_lifestyle_list[0].lifestyle_contents_list[i].specialization_coef !== 0){
        defaultSort = false;
      }
      else{continue;}
    }
    if(defaultSort){
      //レスポンスpropsをさらにidで並べ替え
      for(let i = 0; i < re_lifestyle_list.length; i++){   
        re_lifestyle_list[i].lifestyle_contents_list.sort((a, b) => {    
          if(parseInt(a.lifestyle_contents_id) > parseInt(b.lifestyle_contents_id)) return 1;
          if(parseInt(a.lifestyle_contents_id) < parseInt(b.lifestyle_contents_id)) return -1;
          return 0;
        });
      }
    }
    else{
      //レスポンスpropsをさらに特徴係数で並べ替え
      for(let i = 0; i < re_lifestyle_list.length; i++){   
        re_lifestyle_list[i].lifestyle_contents_list.sort((a, b) => {    
          if(a.specialization_coef > b.specialization_coef) return -1;
          if(a.specialization_coef < b.specialization_coef) return 1;
          return 0;
        });
      }
    }
    //レスポンスのソートに合わせてリクエストpropsをソート
    let requestList = [];
    for(let i = 0; i < re_lifestyle_list.length; i++){
      for(let j = 0; j < re_lifestyle_list.length; j++){
        if(re_lifestyle_list[i].lifestyle_id === lifestyle_list[j].lifestyle_id){
          requestList.push(lifestyle_list[j]);
        }
        else{continue;}
      }
    }
    this.setState({
      modalDisplayLifestyle: modal_display_list,
      requestLifestyle: requestList,
      prevLifestyle: requestList,
      responseLifestyle: re_lifestyle_list,
    });
    //表示用15個だけのリストを作成
    let displayResponseList = [];
    if(!this.props.lifestyleDisplayList){
      for(let i = 0; i < 15; i++){
        displayResponseList.push(re_lifestyle_list[i])
      }
    }
    else{
      for(let i = 0; i < this.props.lifestyleDisplayList.length; i++){
        for(let j = 0; j < re_lifestyle_list.length; j++){
          if(re_lifestyle_list[j].lifestyle_id === this.props.lifestyleDisplayList[i]){
            displayResponseList.push(re_lifestyle_list[j]);
          }
          else{continue;}
        }
      }
    }
    this.setState({
      displayLifestyle: displayResponseList,
      displayFutureLifestyle: displayResponseList,
      displayAllZeroLifestyle: displayResponseList
      // displayFutureLifestyle: this.props.lifestylePrevDisplayList
    });
  }

  /**
   * (React組み込み関数)絞り込み結果が更新された時、画面表示用にデータを整理する
   * ※Top.jsにcomponentDidUpdate()の公式URLを記載
   * @param {Array} prevProps - 今までの絞り込み結果
   * @param {Array} lifestyle_list - 絞り込みのリクエスト
   * @param {Array} re_lifestyle_list - 絞り込みのレスポンス
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   * @param {Array} displayResponseList - 画面表示させる項目のレスポンスを格納
   * @param {number} count - 画面表示させる項目カウント用変数
   */
  componentDidUpdate(prevProps){
    if(prevProps.responseLifestyle !== this.props.responseLifestyle){
      let lifestyle_list = this.props.requestLifestyle;
      let re_lifestyle_list = this.props.responseLifestyle;
      let modal_display_list = JSON.parse(JSON.stringify(this.props.responseLifestyle));
      //レスポンスpropsをorder順にソート
      re_lifestyle_list.sort((a, b) => {
        if(a.lifestyle_order < b.lifestyle_order) return -1;
        if(a.lifestyle_order > b.lifestyle_order) return 1;
        return 0;
      });
      //モーダル表示用にレスポンスpropsをorder順にソート
      modal_display_list.sort((a, b) => {
        if(a.lifestyle_order < b.lifestyle_order) return -1;
        if(a.lifestyle_order > b.lifestyle_order) return 1;
        return 0;
      });
      //モーダル表示用にレスポンスpropsをさらにidで並べ替え
      for(let i = 0; i < modal_display_list.length; i++){   
        modal_display_list[i].lifestyle_contents_list.sort((a, b) => {    
          if(parseInt(a.lifestyle_contents_id) > parseInt(b.lifestyle_contents_id)) return 1;
          if(parseInt(a.lifestyle_contents_id) < parseInt(b.lifestyle_contents_id)) return -1;
          return 0;
        });
      }
      //レスポンスpropsをさらに特徴係数で並べ替え
      for(let i = 0; i < re_lifestyle_list.length; i++){      
        re_lifestyle_list[i].lifestyle_contents_list.sort((a, b) => {    
          if(a.specialization_coef > b.specialization_coef) return -1;
          if(a.specialization_coef < b.specialization_coef) return 1;
          return 0;
        });
      }
      //レスポンスのソートに合わせてリクエストpropsをソート
      let requestList = [];
      for(let i = 0; i < re_lifestyle_list.length; i++){
        for(let j = 0; j < re_lifestyle_list.length; j++){
          if(re_lifestyle_list[i].lifestyle_id === lifestyle_list[j].lifestyle_id){
            requestList.push(lifestyle_list[j]);
          }
          else{continue;}
        }
      }
      this.setState({
        modalDisplayLifestyle: modal_display_list,
        requestLifestyle: requestList,
        prevLifestyle: requestList,
        responseLifestyle: re_lifestyle_list,
      });
      //表示用5個だけのリストを作成
      let displayResponseList = [];
      let count = 0;
      re_lifestyle_list.forEach(d => {
        if (count < this.state.displayLifestyle.length
          && this.state.displayLifestyle[count].lifestyle_id === d.lifestyle_id){
          displayResponseList.push(d);
          count++;
        }
      });
      this.setState({displayLifestyle: displayResponseList});
      let idList = [];
      displayResponseList.forEach(d => {
        idList.push(d.lifestyle_id);
      })
      this.props.setDisplayLifestyle(idList);
    }
  }

  /**
   * 絞り込みモーダルを開いた際の、モーダル内のチェック状態を確認する
   * @param {Array} currList - ユーザが操作した最新のチェック状態
   * @param {Array} prevList - 絞り込み時のチェック状態
   * @param {Array} sortedCurrList - currListのチェックされている項目idを格納
   * @param {Array} sortedPrevList - prevListのチェックされている項目idを格納
   */
  openModalLife(){
    let currList = JSON.parse(JSON.stringify(this.state.requestLifestyle));
    let prevList = JSON.parse(JSON.stringify(this.state.prevLifestyle));
    let sortedCurrList = [];
    let sortedPrevList = [];

    for(let i = 0; i < prevList.length; i++){
      if(currList[this.state.selectedId].lifestyle_id === prevList[i].lifestyle_id){
        sortedCurrList.push(currList[this.state.selectedId].lifestyle_filter_list.filter(d => (d.lifestyle_filter_selected === 1)));
        sortedCurrList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        sortedPrevList.push(prevList[i].lifestyle_filter_list.filter(d => d.lifestyle_filter_selected === 1));
        sortedPrevList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        //絞り込みボタンが押された
        if(sortedCurrList.toString() === sortedPrevList.toString()){
          this.setState({prevLifestyle: currList});
        }
        //絞り込みボタンが押されなかったけどユーザがチェックを操作した後モーダルが閉じられた
        else if(sortedCurrList.toString() !== sortedPrevList.toString()){
          this.setState({requestLifestyle: prevList});
        }
      }
    }
  }


  /**
   * Top.jsまたはpersonaDetail.js(親)が持つ絞り込み条件を更新する
   * @param {Array} requestData - ライフスタイルテーブル内での最新の絞り込み条件
   */
  setData(){
    let requestData = JSON.parse(JSON.stringify(this.state.requestLifestyle));
    this.props.setRequestLifestyle(requestData);
    this.setState({prevLifestyle: requestData});
    this.closeFilterModal.bind(this)();
  }

  /**
   * クリックされた項目idが属するブロックidを更新する
   * @param {number} id - クリックされた項目id
   */
  selectedState(id){
    for(let i = 0; i < this.state.responseLifestyle.length; i++){
      if(this.state.responseLifestyle[i].lifestyle_id === id){
        this.setState({selectedId: i});
      }
      if(this.state.modalDisplayLifestyle[i].lifestyle_id === id){
        this.setState({selectedModalId: i});
      }    
    else{continue;}
    }
  }

  /**
   * 絞り込みモーダルを開く
   */
  openFilterModal(){
    this.setState({ filterModalIsOpen: true });
    this.openModalLife.bind(this)();
  }

  /**
   * 表示切替モーダルを開く
   */
  openSwitchModal() {
    if(localStorage.getItem("tr") === "true"){
      this.setState({ switchModalIsOpen: false });   
    }
    else{
      this.setState({ switchModalIsOpen: true });   
    }
  }

  /**
   * 絞り込みモーダルを閉じる
   */
  closeFilterModal() {
    this.setState({filterModalIsOpen: false});
  }

  /**
   * 表示切替モーダルを閉じる
   */
  closeSwitchModal(action) {
    if(action === "display"){
      let futureList = [...this.state.displayLifestyle];
      let idList = [];
      futureList.forEach(d => {
        idList.push(d.lifestyle_id);
      })
      this.setState({
        displayLifestyle: futureList,
        displayFutureLifestyle: futureList,
        displayAllZeroLifestyle: futureList
      })
      this.props.setDisplayLifestyle(idList);
      this.setState({switchModalIsOpen: false});
    }
    else{
      let prevList = [...this.state.displayFutureLifestyle]
      this.setState({
        displayLifestyle: prevList,
        switchModalIsOpen: false
      });
    }
  }

  /**
   * ライフスタイルテーブルを作成する
   * @param {object} d - ライフスタイル項目情報
   * @return {JSX} ライフスタイルテーブルを返す
   */ 
  lifestyleTableGenerator = d => {
    if(d !== undefined){
      return(
        <Row className="accordionDisp" key={`${d.lifestyle_id}_${d.lifestyle_name}`}>
          <Col lg={6} xl={6} xs={6}>
            <b><u
              onMouseDown={this.selectedState.bind(this, d.lifestyle_id)}
              onMouseUp={this.openFilterModal.bind(this)}
              style={{color : "gray", cursor: "pointer"}}
            >
              {d.lifestyle_name}
            </u></b>
              <img src={filter} alt="" width="10" height="10"/>             
          </Col>
          <Col lg={6} xl={6} xs={6} className="toggleable">
            <input className="toggleable__control hidden--visually hidden" id={d.lifestyle_id} type="checkbox" style={{display: "none"}}/>
            <label className="toggleable__label" htmlFor={d.lifestyle_id}>
            <div className="life-over-text">
              <u title={d.lifestyle_contents_list[0].lifestyle_contents_name} style={{textDecoration: "none"}}>
                {d.lifestyle_contents_list[0].lifestyle_contents_name}
              </u>
            </div>
            </label>
            <div className="toggleable__content">
                {d.lifestyle_contents_list.map(x => {
                  return (
                  <div className="accordionDisp" key={`accordion_contents_${x.lifestyle_contents_id}`}>
                    <div className="over-text px-0" style={{width: "60%"}}>
                      <u title={x.lifestyle_contents_name
                          +"　"+x.lifestyle_contents_frequency+"人　"
                            +(Math.round(x.specialization_coef*100)/100).toFixed(2)}
                        style={{textDecoration: "none"}}
                      >
                        {x.lifestyle_contents_name}
                      </u>
                    </div>
                    <div className="px-0" style={{textAlign: "end"}}>
                      <u title={x.lifestyle_contents_name
                          +"　"+x.lifestyle_contents_frequency+"人　"
                            +(Math.round(x.specialization_coef*100)/100).toFixed(2)}
                        style={{textDecoration: "none", textAlign: "end"}}
                      >
                        {x.lifestyle_contents_frequency}人
                      </u>
                    </div>
                    <div className="px-0 ml-auto">
                      <u title={x.lifestyle_contents_name
                          +"　"+x.lifestyle_contents_frequency+"人　"
                            +(Math.round(x.specialization_coef*100)/100).toFixed(2)}
                        style={{textDecoration: "none"}}
                      >
                        {(Math.round(x.specialization_coef*100)/100).toFixed(2)}
                      </u>
                    </div>
                  </div>
                  )}
                )}
            </div>
          </Col>
        </Row>
      );
    }
    else{}
  };

  /**
   * ライフスタイル文の表示切替を行う
   * @param {object} d - ライフスタイルブロック情報
   * @return {JSX} ライフスタイル表示チェックボックスを返す
   */
  lifestyleSwitchGenerator = d => {
    return(
      <span key={`display_contents_${d.lifestyle_id}`}>
        <label>
          <input
            name="lifestyle"
            id={d.lifestyle_name}
            type="checkbox"
            onChange={this.handleChange.bind(this, d.lifestyle_name, d.lifestyle_id)}
            defaultChecked={this.displaySwitchDefaultChecked.bind(this, d.lifestyle_id)()}
            className="mx-2"
          />
          {d.lifestyle_name}
        </label><br/>
      </span>
    );
  };

  /**
   * 絞り込みが行われた直後の表示切替チェックボックスのチェック状態を再現する
   * @param {number} displayId - 表示切替項目id
   * @param {Array} displayDefaultCheckedList - テーブル内に表示しているライフスタイル項目リスト
   * @return {boolean} チェック状態の真偽値
   */
  displaySwitchDefaultChecked(displayId){
    let displayDefaultCheckedList = this.state.displayLifestyle;
    for(let j = 0; j < displayDefaultCheckedList.length; j++){
      if(displayDefaultCheckedList[j].lifestyle_id === displayId){
        return true;
      }
      else{continue;}
    }
    return false;
  }

  /**
   * 表示項目を切り替える
   * @param {string} lifeName - 表示切替項目名
   * @param {number} lifeId - 表示切替項目id
   * @param {Array} displayList - テーブル内に表示しているライフスタイル項目リスト
   * @param {Array} reposenseList - 絞り込み結果のレスポンス
   * @param {number} index - 選択された表示項目のインデックス
   * @param {Array} newList - 表示項目リストを格納
   * @param {Array} setToArr - 更新された表示項目リスト
   */
  handleChange = (lifeName, lifeId) => {
    let displayList = [...this.state.displayLifestyle];
    let reposenseList = this.state.responseLifestyle;
      if(document.getElementById(lifeName).checked === true){
        if(displayList.length > 14 ){
          document.getElementById(lifeName).checked = false;
          alert("15項目までしか選択できません。チェックを減らしてください。");
          return false;
        }
        else{
          for(let i = 0; i < reposenseList.length; i++){
            //チェックされてないか確かめる
            if(reposenseList[i].lifestyle_name === lifeName){
              displayList.push(reposenseList[i]);
              break;
            }
          }
        }
      }
      //チェックが外れた
      else if(document.getElementById(lifeName).checked === false){
        for(let i = 0; i < displayList.length; i++){
          //既にチェックされてる
          if(lifeId === displayList[i].lifestyle_id){
            //表示リストからIDを削除
            let index = displayList.indexOf(displayList[i]);
            displayList.splice(index, 1);
            let newList = new Set(displayList);
            let setToArr = Array.from(newList);
            displayList = setToArr;
          }
        }
        // displayList.sort((a, b) => {
        //   if(a.lifestyle_order < b.lifestyle_order) return -1;
        //   if(a.lifestyle_order > b.lifestyle_order) return 1;
        //   return 0;
        // });
      }
      this.setState({displayLifestyle: displayList});
    }

  /**
   * 絞り込みチェックボックスを作成する
   * @param {object} d - ライフスタイル項目情報
   * @param {boolean} trialDisable - トライアルユーザ機能制限
   * @return {JSX} チェックボックス付き絞り込み項目を返す
   */
  lifestyleCheckboxGenerator = d => {
    let trialDisable;
    if(localStorage.getItem("tr") === "true"){
      if(this.state.responseLifestyle[this.state.selectedId].lifestyle_order === 0
        || this.state.responseLifestyle[this.state.selectedId].lifestyle_order === 1
        || this.state.responseLifestyle[this.state.selectedId].lifestyle_order === 2){
          trialDisable = false;
        }
        else{
          trialDisable = true;
        }
      }
      else{
        trialDisable = false;
      }
    return(
      <span key={`filter_contents_${this.state.selectedId}${d.lifestyle_contents_id}`}>
        <label>
          <input
            name="lifestyle"
            id={d.lifestyle_contents_name}
            type="checkbox"
            defaultChecked={this.filterDefaultChecked.bind(this, d.lifestyle_contents_id)()}
            onClick={this.stateChange.bind(this, d.lifestyle_contents_id)}
            className="mx-2"
            disabled={trialDisable}
          />
          <div style={{display:"inline-block"}}>{d.lifestyle_contents_name}</div>
        </label>
        <span className="badge badge-pill badge-secondary ml-2">{d.lifestyle_contents_frequency}</span>
        <br/>
      </span>
    );
  };

  /**
   * 絞り込み結果が更新された直後のチェック状態を再現する
   * @param {number} checkBoxId - 絞り込み項目id
   * @return {boolean} 真偽値を返す
   */
  filterDefaultChecked(checkBoxId){
    for(let i = 0; i < this.state.requestLifestyle[this.state.selectedId].lifestyle_filter_list.length; i++){
      if(this.state.requestLifestyle[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_id === checkBoxId
         && this.state.requestLifestyle[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_selected === 0){
        return false;
      }
      else if(this.state.requestLifestyle[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_id === checkBoxId
         && this.state.requestLifestyle[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_selected === 1){
        return true;
      }
    }
  }

  /**
   * クリックされた絞り込みチェックボックスのチェック状態を反転させる
   * @param {number} checkBoxId - 絞り込み項目id
   * @param {Array} requestList - 絞り込み結果のリクエスト 
   */
  stateChange(checkBoxId){
    let requestList = JSON.parse(JSON.stringify(this.state.requestLifestyle));
    for(let i = 0; i < requestList[this.state.selectedId].lifestyle_filter_list.length; i++){
      if(requestList[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_id === checkBoxId 
        && requestList[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_selected === 0){
        requestList[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_selected = 1;
        this.setState({requestLifestyle: requestList});
      }
      else if(requestList[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_id === checkBoxId 
        && requestList[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_selected === 1){
        requestList[this.state.selectedId].lifestyle_filter_list[i].lifestyle_filter_selected = 0;
        this.setState({requestLifestyle: requestList});
      }
    }
  }

  /**
   * 絞り込みモーダル内に表示されている絞り込みチェックボックス全てにチェックを入れる
   * @param {Array} requestList - 絞り込み結果のリクエスト 
   */
  allCheck() {
    let requestList = JSON.parse(JSON.stringify(this.state.prevLifestyle));
    for(let i = 0; i < requestList[this.state.selectedId].lifestyle_filter_list.length; i++){
      for(let j = 0; j < requestList[this.state.selectedId].lifestyle_filter_list.length; j++){
        if(this.state.responseLifestyle[this.state.selectedId].lifestyle_contents_list[i].lifestyle_contents_id === requestList[this.state.selectedId].lifestyle_filter_list[j].lifestyle_filter_id){
        requestList[this.state.selectedId].lifestyle_filter_list[j].lifestyle_filter_selected = 1;
        document.getElementById(requestList[this.state.selectedId].lifestyle_filter_list[j].lifestyle_filter_name).checked = true;
        }
        else{continue;}
      }
    }
    this.setState({requestLifestyle: requestList});
  }

  /**
   * 絞り込みモーダル内に表示されている絞り込みチェックボックス全てのチェックを外す
   * @param {Array} requestList - 絞り込み結果のリクエスト 
   */
  allUnCheck() {
    let requestList = JSON.parse(JSON.stringify(this.state.requestLifestyle));
    for(let i = 0; i < requestList[this.state.selectedId].lifestyle_filter_list.length; i++){
      for(let j = 0; j < requestList[this.state.selectedId].lifestyle_filter_list.length; j++){
        if(this.state.responseLifestyle[this.state.selectedId].lifestyle_contents_list[i].lifestyle_contents_id === requestList[this.state.selectedId].lifestyle_filter_list[j].lifestyle_filter_id){
          requestList[this.state.selectedId].lifestyle_filter_list[j].lifestyle_filter_selected = 0;
          document.getElementById(requestList[this.state.selectedId].lifestyle_filter_list[j].lifestyle_filter_name).checked = false;
        }
        else{continue;}
      }
    }
    this.setState({requestLifestyle: requestList});
  }

  /**
   * 表示切替モーダルのチェックを全解除する
   */
  allUnCheckSwitchbox(){
    //全てのチェックを外す
    this.state.responseLifestyle.forEach(d => {
      document.getElementById(d.lifestyle_name).checked = false;
    });
    //表示切替リストを空にする
    // this.setState({displayFutureLifestyle: []});
    this.setState({displayLifestyle: []});
  }

  /**
   * 絞り込みモーダルのタイトル名を返す
   * @param {boolean} trialDisable - トライアルユーザの機能制限
   * @return {string} 絞り込みタイトル
   */
  modalNameSwitcher(){
    return this.state.responseLifestyle[this.state.selectedId].lifestyle_name;
  }

  /**
   * 実際にウィンドウに表示する
   * @param {boolean} trialDisable - トライアルユーザ機能制限(絞り込み)
   * @param {boolean} trialSwitchDisable - トライアルユーザ機能制限(表示切替)
   * @return {JSX} ライフスタイルテーブル・絞り込み/表示切替モーダル表示用HTMLを返す
   */
  render() {
    let trialDisable;
    let trialSwitchDisable;
    if(localStorage.getItem("tr") === "true"){
      trialSwitchDisable = true;
      if(this.state.selectedId === 0 || this.state.selectedId === 1　|| this.state.selectedId === 2){
        trialDisable = false;
      }
      else{
        trialDisable =true;
      }
    }
    else{
      trialSwitchDisable = false;
      trialDisable = false;
    }
    return (
      <div>
        {/* 絞り込みモーダル */}
        <Modal 
          show={this.state.filterModalIsOpen}
          onHide={this.closeFilterModal}
          dialogClassName="modal-50w"
        >
          <Modal.Header style={{textAlign: "center"}}>
            <h5 style={{marginBottom: 0}}><b>{this.modalNameSwitcher()}絞り込み</b></h5>
          </Modal.Header>
          <Modal.Body>
            <div className="body" style={{overflowY: "scroll", height: "500px" }}>
              {this.state.modalDisplayLifestyle[this.state.selectedModalId].lifestyle_contents_list.map(x => this.lifestyleCheckboxGenerator(x))}
            </div>
            <div className="d-flex justify-content-center">
              <Button
                variant="secondary"
                onClick={this.allCheck}
                size="sm"
                style={{marginTop: 10}}
                disabled={trialDisable}
              >
                全選択
              </Button>
              <Button
                variant="secondary"
                onClick={this.allUnCheck}
                size="sm"
                disabled={trialDisable}
                style={{ marginTop: 10, marginLeft: 10}}
              >
                全解除
              </Button>
            </div>
            <div className="d-flex justify-content-center">
              <Button
                variant="secondary"
                onClick={this.setData}
                style={{ marginTop: 10, marginBottom: 20, marginRight: 10, marginLeft: -10}}
                size="sm"
                disabled={trialDisable}
              >
                絞り込み
              </Button>
              <Button
                variant="secondary"
                onClick={this.closeFilterModal}
                style={{marginTop: 10, marginBottom: 20}}
                size="sm"
              >
                閉じる
              </Button>
            </div>
          </Modal.Body>
          </Modal>
          {/* 表示切替モーダル */}
          <Modal
            show={this.state.switchModalIsOpen}
            onHide={this.closeSwitchModal}
            dialogClassName="modal-40w"
          >
            <Modal.Header style={{textAlign: "center"}}>
              <h5 style={{marginBottom: 0}}><b>価値観表示切替</b></h5>
            </Modal.Header>
            <Modal.Body>
              <div className="body" style={{overflowY: "scroll", height: "580px" }}>
                {this.state.responseLifestyle.map(x => this.lifestyleSwitchGenerator(x))}
              </div>
              <div className="d-flex justify-content-center">
                <Button
                  variant="secondary"
                  onClick={this.allUnCheckSwitchbox}
                  style={{marginTop: 10, marginBottom: 20}}
                  size="sm"
                  className="d-block"
                >
                  すべてのチェックを外す
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onMouseUp={this.closeSwitchModal.bind(this, "display")}
                  style={{ marginTop: 10, marginRight: 10, marginLeft: 10, marginBottom: 20 }}
                >
                  チェックした項目を表示する
                </Button>
                <Button
                  variant="secondary"
                  onClick={this.closeSwitchModal.bind(this, "close")}
                  style={{marginTop: 10, marginBottom: 20}}
                  size="sm"
                  className="d-block"
                >
                  閉じる
                </Button>
              </div>
            </Modal.Body>
          </Modal>
          <div style={{textAlign: "right"}}>
            <Button
              variant="secondary"
              size="sm"
              onClick={this.openSwitchModal.bind(this)}
              style={{position: "right"}}
              className="mb-2"
              disabled={trialSwitchDisable}
            >
              表示切替
              <img src={selector} alt="" width="15" height="15" className="ml-2 mb-1" />
            </Button>
          </div>
          {/* 価値観テーブル */}
          <div className="px-2 scrollHandleLifestyle">
            <div>
              {this.state.displayLifestyle.map(x => this.lifestyleTableGenerator(x))}
            </div>
          </div>
        </div>
    );
  }
}
export default LifeStyleTable;