import React from "react";
import "./App.css";
import { Button, Modal } from "react-bootstrap";
import pic from "./images/icons/pic_g.png";

/**
 * ポジショニングマップ描画クラス
 */
class PositioningMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      graphTitle: "",
      graphId: "",
      positionModalIsOpen: false ,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setIdAndTitle = this.setIdAndTitle.bind(this);
    this.positionMapGenerator = this.positionMapGenerator.bind(this);
  }

  /**
   * ポジショニングマップモーダルを開く
   */
  openModal() {
    this.setState({positionModalIsOpen: true}); 
  }

  /**
   * ポジショニングマップモーダルを閉じる
   */
  closeModal() {
    this.setState({ positionModalIsOpen: false });
  }

  /**
   * グラフタイトル名を更新する
   * @param {string} title - ポジショニングマップタイトル名 
   */
   setIdAndTitle(id, title){
    if(title !== "no graph"){
      this.props.setImage(id);
      this.setState({ 
        graphTitle: title ,
        graphId: id
      });
    }
    this.openModal();
  }

  /**
   * ポジショニングマップモーダルを作成する
   * @param {string} disableTrial - トライアルユーザの機能制限
   * @return {JSX} ポジショニングマップ表示用HTMLを返す
   */
  positionMapGenerator(){
    if(parseInt(this.props.id) !== parseInt(this.state.graphId) || this.props.labelImage === ""){
      return (
        <div className="py-3 px-3 mt-3">
          <b style={{fontSize: "150%"}}>{this.state.graphTitle}</b><br/>
          <div className="d-flex justify-content-center mt-4 pt-4">
            <div className="spinner-border text-secondary" style={{width: "5rem", height: "5rem"}} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      );
    }
    else{
      let disableTrial;
      if(localStorage.getItem("tr") === "true"){
        disableTrial = "none";
      }
      else{
        disableTrial = "auto";
      }
      return (
        <div className="py-3 px-3 mt-3">
          <b style={{fontSize: "150%"}}>{this.state.graphTitle}</b><br/>
          <div className="ml-auto" style={{textAlign: "right", verticalAlign: "bottom"}}>
            <a title="画像を保存" href={this.props.labelImage} download="ポジショニングマップ.png" style={{pointerEvents: disableTrial}}>
              <img 
                src={pic} 
                className="ml-auto" 
                width="30px" 
                height="30px" 
                style={{cursor: "pointer", verticalAlign: "bottom"}}
                alt=""
              />
            </a>
          </div>
          <div className="d-flex justify-content-center">
            <img src={this.props.labelImage} alt=""/>
          </div>
        </div>
      );
    }
  }

  /**
   * ポジショニングマップサムネイルを描画する
   * @param {object} d - ポジショニングマップサムネイル情報
   * @return {JSX} ポジショニングマップのサムネイルを返す
   */
  pmapThumbnail = d => {
    return(
      <div id={d.title} className="d-flex flex-column mx-1 my-1" key={d.id} width="350px">
        <b style={{textAlign: "center", borderBottom: "medium solid #000000"}}>{d.title}</b>
        <img alt="" src={d.image_src} style={{cursor: "pointer"}} onClick={this.setIdAndTitle.bind(this, d.id, d.title)}/>
      </div>
    );
  };

  /**
   * 実際にウィンドウに表示する
   * @return {JSX} ポジショニングマップ表示用HTMLを返す
   */
  render() {
    return　(
      <div>
        <Modal 
          show={this.state.positionModalIsOpen}
          onHide={this.closeModal}
          dialogClassName="modal-70w"
        >
          <Modal.Header className="py-3"/>
          <Modal.Body>
            {this.positionMapGenerator()}
            <div className="py-3 d-flex">
              ※対象範囲外で、一部非表示となっているプロットがあります。
              <Button
                variant="secondary"
                onClick={this.closeModal}
                className="ml-auto d-block"
                size="sm"
              >
                閉じる
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <div width="1500px" className="d-flex flex-wrap mb-2">
          {this.props.pmapPersona.length? this.props.pmapPersona.map(d => this.pmapThumbnail(d)) : false}
        </div>
      </div>
    )
  }
}
export default PositioningMap;
