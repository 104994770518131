// ss:{"name": 202211_2000, "sex": 1}
// ssから調査年だけ整形して取り出す関数（ex:2022）
export const formatSurveyYear = () => {
    const year = JSON.parse(sessionStorage.getItem("ss")).name.substr(0, 4);
    return year;
}

// ssから調査データを整形して取り出す関数（ex:202211_2000）
export const formatSurvey = () => {
    const survey = JSON.parse(sessionStorage.getItem("ss")).name;
    return survey;
}

// ss用に整形する関数
export const formatSs = (survey, sex) => {
    const selectedSurvey = JSON.stringify({"name": survey, "sex": sex});
    return selectedSurvey;
}
