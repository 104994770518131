import { IndexedKv } from "indexed-kv";
const crypto = require('crypto');

const DB_NAME = "PersonaDB";
const TABLE_NAME = "cache";
const indexedKV = new IndexedKv(DB_NAME, TABLE_NAME);

const generate_hash = (raw_string) => {
    return crypto.createHash('sha1').update(raw_string).digest('hex');
}
/**
 * データストアに文字列リストを格納し、キーとの対応結果を返却する(async)
 *
 * @param {Array} values
 * @returns キーとの対応結果
 */
export const setDS = async (values) => {
    let results = {};
    for (const v of values) {
        const key = generate_hash(v);
        await indexedKV.set(key, v);
        results[v] = key;
    }
    return results;
}
/**
 * データストアからキーリストを用いて文字列リストを復元する
 *
 * @param {Array} keys - クエリ展開キーのリスト
 * @returns 文字列リスト
 */
export const getDS = async (keys) => {
    let results = {};
    for (const k of keys) {
        results[k] = await indexedKV.get(k);
    }
    return results;
}
/**
 *データストアをクリアする
 *
 */
export const clearDS = () => {
    indexedDB.deleteDatabase(DB_NAME);
}