import React from "react";
import { Col } from "react-bootstrap";
import * as d3 from 'd3';
import ReactGridTreeDataBinding from "./ReactGridTreeDataBinding";
import pic from "./images/icons/pic_g.png";
import saveSvgAsPng from "save-svg-as-png";

/**
 * KWマップ描画クラス
 */
class KeywordMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      word: "",
      node: "",
      link: "",
    }
    this.setWord = this.setWord.bind(this);
    this.pic = this.pic.bind(this);
  }

  /**
   * (React組み込み関数)絞り込み状態を更新し、KWマップの再描画を行う
   * ※Top.jsにcomponentDidUpdate()の公式URLを記載
   * @param {Array} prevProps - KWマップ情報 
   * @param {Array} nodes - ノード情報
   * @param {Array} links - リンク情報
   */
  componentDidUpdate(prevProps){
    if(prevProps.nodes !== this.props.nodes){
      let nodes = this.props.nodes;
      let links = this.props.links;
      this.getData.bind(this, links, nodes)();
    }
  }

  /**
   * (React組み込み関数)ユーザがKWマップを操作する度にKWマップを描画し直す
   * ※App.js内にcomponentDidMount()の公式URLを記載
   * @param {Array} nodes - ノード情報
   * @param {Array} links - リンク情報
   */
  componentDidMount(){
    let nodes = this.props.nodes;
    let links = this.props.links;
    this.getData.bind(this, links, nodes)();
  }

  /**
   * KWマップ情報を取得し、描画関数に受け渡す
   * @param {Array} linkList - リンク情報
   * @param {Array} nodeList - ノード情報
   */
  getData(linkList, nodeList){
    let links = linkList.map(d => Object.create(d));
    let nodes = nodeList.map(d => Object.create(d));
    this.setState({node: nodes, link: links});
    this.chart(links, nodes);
  }

  /**
   * ノードの色分けを行う
   * @param {Array} scale - ノードに使用するカラーコードのリスト
   * @return {string} ノードの色
   */
  color = () => {
    const scale = ["#82ccd2","#83cdd3","#fff100","#ef879c","#a7b1b5", "#00b0bb"];
    return d => scale[d.group];
  }

  /**
   * ユーザが選択したノードのラベル情報を更新する
   * @param {string} word - ラベル文字列
   */
  setWord(word){
    this.setState({
      word: word
    });
    this.setState({
      word: ""
    });
  }

/**
 * KWマップ操作(拡大/縮小、移動)の処理を行う
 * @param {*} simulation - ドラッグ時のイベント
 */
  drag = simulation => {
  
    function dragstarted(d) {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      d.fx = d.x;
      d.fy = d.y;
    }
    
    function dragged(d) {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    }
    
    function dragended(d) {
      if (!d3.event.active) simulation.alphaTarget(0);
      d.fx = null;
      d.fy = null;
    }

    return d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  }
    
  /**
  * KWマップの描画を行う
  * @param {Array} links - リンク情報
  * @param {Array} nodes - ノード情報
  */ 
  chart = (links, nodes) => {
    const svg = d3.selectAll("svg");
    const w = document.getElementById("svg").clientWidth;
    const h = document.getElementById("svg").clientHeight;
    let zoomLayer  = svg.append("g");

    if(svg.length > 0){
      d3.selectAll("svg").remove();
    }

    //ズーム時の処理を設定
    let zoomed = function() {
      zoomLayer.attr("transform", d3.event.transform);
    }
    //ズームイベントをsvg要素に束縛
    svg.call(d3.zoom()
      .on("zoom", zoomed));	

    let simulation = d3.forceSimulation(nodes)
      .force("link", d3.forceLink(links).id(d => d.id))
      .force("center", d3.forceCenter(w /2, h /2))
      .force("collide",d3.forceCollide(50))//接触力(大きいと)より離れる
    //リンク
    let link = zoomLayer
      .attr("stroke", "#999")
      .attr("stroke-opacity", 0.6)
      .selectAll("line")
        .data(links)
        .enter().append("line")
        .attr("stroke-width", d => Math.sqrt(d.cnt));

    let node = zoomLayer
      .attr("class", "nodes")
      .selectAll("g")
      .data(nodes)
      .enter()
      .append("g");

    //ノード
    node.append("circle")
      .attr("r", d => String(d.cnt * 5)+"px")
      .attr("fill", this.color())
      .on("click", d => this.setWord(d.label));
    if(localStorage.getItem("tr") === "false"){
      node.style("cursor", "pointer")
    }
    else{
      node.style("cursor", "default")
    }
    node.append("text")
      .text(d => d.label)
      .style("font-family", "Sawarabi Gothic, sans-serif")
      .style("stroke",  "none")
      .style("fill", "#6c757d")
      .style("text-anchor", "middle")
      .on("click", d => this.setWord(d.label));
    node.append("title")
      .text(d => d.label);
    simulation.force("link")
      .links(links);

  simulation.nodes(nodes).on("tick", () => {
    link
    .attr("x1", function (d) { return d.source.x; })
    .attr("y1", function (d) { return d.source.y; })
    .attr("x2", function (d) { return d.target.x; })
    .attr("y2", function (d) { return d.target.y; });

    node
      .attr("transform", d => "translate(" + d.x + "," + d.y + ")");
    });
    return svg.node();
  }

  /**
   * KWマップをpng画像としてダウンロードする
   * @param {object} imageOptions - 画像ダウンロード設定値
   */
  pic() {
    if(localStorage.getItem("tr") === "false"){
      const imageOptions = {
        scale: 5,
        encoderOptions: 1,
        backgroundColor: 'white',
      }
      saveSvgAsPng.saveSvgAsPng(document.getElementById("svg"), "KWマップ.png", imageOptions);
    }
    else{
      
    }
  }
    
  /**
   * 実際にウィンドウに表示する
   * @param {boolean} disableTrial - トライアルユーザ機能制限
   * @return {JSX} KWマップを返す
   */
  render() {
    let disableTrial;
    if(localStorage.getItem("tr") === "true"){
      disableTrial = "none";
    }
    else{
      disableTrial = "auto";
    }
    return (
      <div className="d-flex">
        <Col md={6} className="d-flex flex-column">
          <b style={{fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000"}} className="mb-2 w-100">自分らしさ</b>
          <ReactGridTreeDataBinding 
            responseFreeform={this.props.responseFreeform}
            keyWord = {this.state.word} 
          />
        </Col>
        <Col md={6} className="d-flex flex-column">
          <b style={{fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000"}} className="mb-2 w-100">KWマップ</b>
          <div style={{textAlign: "right", marginTop: "20px", marginRight: "10px", marginLeft: "auto", verticalAlign: "bottom"}}>
            <img 
              src={pic} 
              title="画像を保存"
              width="20px" 
              height="20px" 
              onClick={this.pic}
              style={{cursor: "pointer", verticalAlign: "top", pointerEvents: disableTrial}}
              alt=""
            />
          </div>
          <div>
            <svg id="svg" height="550px" className="keywordmap"></svg>
          </div>
        </Col>
      </div>
    );
  }
}
export default KeywordMap;
