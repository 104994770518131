import React from "react";
import "./App.css";
import { Button, Table, Modal } from "react-bootstrap";
import {Tabs, TabList, Tab, TabPanel} from 'react-tabs';
import excels from "./images/icons/xlsx.png";
import dustbox from "./images/icons/delete.png";
import man from "./images/icons/man.png";
import woman from "./images/icons/woman.png";
import {SortingState, IntegratedSorting, PagingState, CustomPaging} from '@devexpress/dx-react-grid';
import {Grid, Table as TableTable, TableHeaderRow, PagingPanel} from '@devexpress/dx-react-grid-bootstrap4';
import axios from 'axios';
import { saveAs } from "file-saver";
import { setDS } from "./DataStore";
import Api from "./Api";

window.onunhandledrejection = event => {
  alert("エラーが発生しました。ログイン画面に戻ります。");
  window.location.href = "./#/login";
  localStorage.removeItem("ci");
  localStorage.removeItem("tr");
  localStorage.removeItem("it");
  localStorage.removeItem("rt");
  localStorage.removeItem("sy");
  localStorage.removeItem("ss");
};

/**
 * マイページ描画クラス
 */
class MyPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterModalIsOpen: false,
      listOfFilterDataSavedByUser: "",
      userData: "",
      selectedId: 0,
      compressedList: "",
      queryList: "",
      index: "",
      totalCount: 0,
      currentPage: 0,
      loading: true,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.dataDisp = this.dataDisp.bind(this);
    this.basicStateGenerator = this.basicStateGenerator.bind(this);
    this.basicChildrenGenerator = this.basicChildrenGenerator.bind(this);
    this.lifestyleStateGenerator = this.lifestyleStateGenerator.bind(this);
    this.lifestyleChildrenGenerator = this.lifestyleChildrenGenerator.bind(this);
    this.personaStateGenerator = this.personaStateGenerator.bind(this);
    this.personaChildrenGenerator = this.personaChildrenGenerator.bind(this);
    this.brandStateGenerator = this.brandStateGenerator.bind(this);
    this.brandChildrenGenerator = this.brandChildrenGenerator.bind(this);
    this.exportReport = this.exportReport.bind(this);
    this.deleteData = this.deleteData.bind(this);
    this.pageDisplay = this.pageDisplay.bind(this);
    this.changePage = this.changePage.bind(this);
    this.loadData = this.loadData.bind(this);
  }

  /**
   * (React組み込み関数)初期画面表示のための保存条件リストと会員情報を取得する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {string} jwt - 認証情報 
   * @param {Array} queryList - URLクエリ文字列のリスト
   */
  UNSAFE_componentWillMount(){
    // ブラウザタブタイトルを変更する
    document.title = `マイページ|ペルソナキューブ`;
    let self = this;
    // const take = process.env.REACT_APP_NUMBER_OF_QUERIES
    const take = 10
    //絞り込み条件リスト取得
    new Api().get(`/api/v1/saved_queries?pagenate=1&take=${take}&skip=0`, response => {
      self.setState({
        listOfFilterDataSavedByUser: response.data.queries,
        totalCount: response.data.total_count
      });
      let queryStrList = [];
      for(let i = 0; i < response.data.queries.length; i++){
        // TOPか詳細か区別するパラメータを追加
        if(response.data.queries[i].page_label === "トップページ"){
          let obj = JSON.parse(response.data.queries[i].query)
          obj["switch_persona_detail"] = false
          response.data.queries[i].query = JSON.stringify(obj)
        }
        else{
          let obj = JSON.parse(response.data.queries[i].query)
          obj["switch_persona_detail"] = true
          response.data.queries[i].query = JSON.stringify(obj)
        }

        // 調査年度がどれかを指定するパラメータを追加
        let o = JSON.parse(response.data.queries[i].query)
        if (o["switch_survey"] === undefined) {
          o["switch_survey"] = response.data.queries[i].survey
          response.data.queries[i].query = JSON.stringify(o)
        }

        queryStrList[i] = response.data.queries[i].query;
      }
      self.setState({
        queryList: queryStrList,
        selectedId: 0
      })
      setDS(queryStrList)
      .then(response_key => {
      self.setState({compressedList: response_key});
      })
      self.setState({loading: false});
    });

    //会員情報取得
    new Api().get("/api/v1/profile", response => {
      self.setState({userData: response.data});
    });
    // let s = self.props.survey
    // self.setState({survey: s})
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.survey !== this.props.survey) {
  //     let s = this.props.survey
  //     this.setState({survey: s})
  //   }
  // }


  /**
   * 保存条件モーダルに表示させるデータを更新する
   * @param {number} id - 選択された保存条件id
   */
  dataDisp(id){
    for(let i = 0; i < this.state.listOfFilterDataSavedByUser.length; i++){
      if(this.state.listOfFilterDataSavedByUser[i].id === id){
        this.setState({selectedId: i});
      }
      else{continue;}
    }
    this.openModal();
  }  
  
  /**
   * 条件一覧モーダルを開く
   */
  openModal() {
    this.setState({ filterModalIsOpen: true });
  }
  
  /**
   * 条件一覧モーダルを閉じる
   */
  closeModal() {
    this.setState({ filterModalIsOpen: false });
  }

  /**
   * 会員情報タブ「戻る」ボタンよりトップページへ遷移する
   */
  changePage() {
    window.open(
      "./#",　
      "_self",
      "width=1920, height=1080"
    );
  }

  /**
   * 帳票を出力する
   * @param {number} id - 出力したい保存条件のid
   * @param {string} jwt - 認証情報 
   * @param {number} exportIndex - 出力したい保存条件のインデックス 
   * @param {JSON} queryData - JSON化した保存条件 
   * @param {number} persona_axis_id - ペルソナスケルトン軸id
   * @param {number} persona_id - ペルソナスケルトンid 
   * @param {Array} lifestyle_ids - ライフスタイルブロックidのリスト  
   * @param {Array} purchasing_behavior_ids - 購買行動ブロックidのリスト  
   */
  exportReport(id){
    let jwt = localStorage.getItem("it");
    let exportIndex;
    //保存条件リストの中から保存条件idを調べて帳票出力させる保存条件を見つける
    for(let i = 0; i < this.state.listOfFilterDataSavedByUser.length; i++){
      if(this.state.listOfFilterDataSavedByUser[i].id === id){
        exportIndex = i;
        this.setState({selectedId: i});
      }
      else{continue;}
    }
    //各カテゴリのidを詰めたリストを生成する
    let queryData = JSON.parse(this.state.listOfFilterDataSavedByUser[exportIndex].query);
    let persona_axis_id;
    let persona_id;
    for(let i = 0; i < queryData.persona_list.length; i++){
      for(let j = 0; j < 4; j++){
        if(queryData.persona_list[i].persona_filter_list[j].persona_filter_selected === 1 || queryData.persona_list[i].persona_filter_list[j].persona_filter_selected === "1"){
          let id = parseInt(j);
          persona_id = id + 1;
          persona_axis_id = i;
        }
        else{continue;}
      }
    }
    let lifestyle_ids = [];
    for(let i = 0; i < queryData.lifestyle_list.length; i++){
      lifestyle_ids.push(queryData.lifestyle_list[i].lifestyle_id);
    }
    let purchasing_behavior_ids = [];
    for(let i = 0; i < queryData.purchasing_behavior_list.length; i++){
      purchasing_behavior_ids.push(queryData.purchasing_behavior_list[i].purchasing_behavior_id);
    }
    // 調査データ判定
    // TODO fixme 201909を含む自分がアクセスできる調査のうちひとつの調査を選択する
    let survey;
    // let query = JSON.parse(response_key[get]);
    let assumed_surveys = JSON.parse(localStorage.getItem("sy")).filter((sv)=> {return sv.name.indexOf(queryData.switch_survey) !== -1});
    survey = assumed_surveys.slice(-1)[0].name;

    axios({
      url:`${process.env.REACT_APP_API_ORIGIN}/api/v1/persona_report`, 
      headers:{ "Accept": "application/json",  "Authorization": `bearer ${jwt}`},
      method: "POST",
      data:{
        "filters": queryData, 
        "persona_axis_id": persona_axis_id, 
        "persona_id": persona_id, 
        "lifestyle_ids": queryData.switch_lifestyle_list, 
        "purchasing_behavior_ids": queryData.switch_purchasing_behavior_list,
        "survey": survey,
        "sex": Number(this.state.listOfFilterDataSavedByUser[exportIndex].sex),
      },
      responseType: "blob"
    })
    .then(response => {
      const blob = new Blob([response.data], {
        type: response.data.type
      });
      //帳票ダウンロード
      saveAs(blob, this.state.listOfFilterDataSavedByUser[exportIndex].name + ".xlsx");
    })
  };

  /**
   * ページネートで保存条件を取得する
   * @param {number} e - 表示中ページ番号
   */
   loadData(e){
    //  console.log(e)
     if(!this.state.loading){
      this.setState({
        loading: true,
        selectedId: 0
      });
      let self = this;
      // let skip = e * process.env.REACT_APP_NUMBER_OF_QUERIES
      let skip = e * 10
      // let take = process.env.REACT_APP_NUMBER_OF_QUERIES
      let take = 10
      //保存条件削除後のテーブル情報取得
      new Api().get(`/api/v1/saved_queries?pagenate=1&take=${take}&skip=${skip}`, response => {
        self.setState({
          listOfFilterDataSavedByUser: response.data.queries,
          totalCount: response.data.total_count
        });
        let queryStrList = [];
        for(let i = 0; i < response.data.queries.length; i++){
          // TOPか詳細か区別するパラメータを追加
          if(response.data.queries[i].page_label === "トップページ"){
            let obj = JSON.parse(response.data.queries[i].query)
            obj["switch_persona_detail"] = false
            response.data.queries[i].query = JSON.stringify(obj)
            // console.log(obj)
          }
          else{
            let obj = JSON.parse(response.data.queries[i].query)
            obj["switch_persona_detail"] = true
            response.data.queries[i].query = JSON.stringify(obj)
            // console.log(obj)
          }

          // 調査年度がどれかを指定するパラメータを追加
          let o = JSON.parse(response.data.queries[i].query)
          if (o["switch_survey"] === undefined) {
            o["switch_survey"] = response.data.queries[i].survey
            response.data.queries[i].query = JSON.stringify(o)
            console.log(o)
          }

          queryStrList[i] = response.data.queries[i].query;
        }
        self.setState({queryList: queryStrList})
        setDS(queryStrList)
        .then(response_key => {
          self.setState({compressedList: response_key});
        })
      });
      this.setState({currentPage: e})
      this.setState({loading: false});
     }
   }

  /**
   * 保存条件を削除する
   * @param {number} deleteId - 選択された保存条件のid
   * @param {string} deleteName - 選択された保存条件名
   * @param {string} jwt - 認証情報 
   * @param {Array} queryList - URLクエリ文字列のリスト
   */
  deleteData(deleteId, deleteName){
    let self = this;
    let jwt = localStorage.getItem("it");
    axios({
      url: `${process.env.REACT_APP_API_ORIGIN}/api/v1/saved_queries/${deleteId}/`,
      headers:{ "Accept": "application/json",  "Authorization": `bearer ${jwt}`},
      method: "DELETE",
    })
    .then(() => {
      alert( "「"+deleteName+"」を削除しました。");
      // let skip = this.state.currentPage * process.env.REACT_APP_NUMBER_OF_QUERIES
      let skip = this.state.currentPage * 10
      // let take = process.env.REACT_APP_NUMBER_OF_QUERIES
      let take = 10
      //保存条件削除後のテーブル情報取得
      new Api().get(`/api/v1/saved_queries?pagenate=1&take=${take}&skip=${skip}`, response => {
        self.setState({
          listOfFilterDataSavedByUser: response.data.queries,
          totalCount: response.data.total_count
        });
        let queryStrList = [];
        for(let i = 0; i < response.data.queries.length; i++){
          // TOPか詳細か区別するパラメータを追加
          if(response.data.queries[i].page_label === "トップページ"){
            let obj = JSON.parse(response.data.queries[i].query)
            obj["switch_persona_detail"] = false
            response.data.queries[i].query = JSON.stringify(obj)
            // console.log(obj)
          }
          else{
            let obj = JSON.parse(response.data.queries[i].query)
            obj["switch_persona_detail"] = true
            response.data.queries[i].query = JSON.stringify(obj)
            // console.log(obj)
          }

          // 調査年度がどれかを指定するパラメータを追加
          let o = JSON.parse(response.data.queries[i].query)
          if (o["switch_survey"] === undefined) {
            o["switch_survey"] = response.data.queries[i].survey
            response.data.queries[i].query = JSON.stringify(o)
            console.log(o)
          }

          queryStrList[i] = response.data.queries[i].query;
        }
        self.setState({
          queryList: queryStrList,
          selectedId: 0
        })
        setDS(queryStrList)
        .then(response_key => {
          self.setState({compressedList: response_key});
        })
      });
    })
    .catch((error) => {
      if(error.response.status === 401){
        alert("認証に失敗しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
        window.location.href = "./#/login";        
        console.log("error");
        localStorage.removeItem("ci");
        localStorage.removeItem("tr");
        localStorage.removeItem("it");
        localStorage.removeItem("sy");
        localStorage.removeItem("ss");
        localStorage.removeItem("sv");
        localStorage.removeItem("se");
      }
      else{
        console.log("error");
        localStorage.removeItem("tr");
        localStorage.removeItem("ci");
        localStorage.removeItem("it");
        localStorage.removeItem("sy");
        localStorage.removeItem("ss");
        localStorage.removeItem("sv");
        localStorage.removeItem("se");
      }
    })
  }

  /**
   * 保存条件を再現するために、各ページへ遷移する
   * @param {string} query - 保存条件
   * @param {number} sex - 性別
   * @param {string} label - ページラベル
   * @param {JSON} filterData - JSON化した保存条件
   * @param {number} axisIndex - ペルソナスケルトン軸id
   * @param {number} personaIndex - ペルソナスケルトンid
   * @param {number} index - 該当URL展開キーのインデックス
   * @return {string} 遷移先URL
   */
  pageDisplay(query, sex, label, index){
    // let userAgent = window.navigator.userAgent.toLowerCase();
    // if(userAgent.indexOf('trident') !== -1) {
    //   return undefined;
    // }
    let filterData = JSON.parse(query);
    if(this.state.compressedList !== "" && this.state.queryList !== ""){
      if(label === "トップページ"){
        return `./#/?s=${sex}&filter=`+ encodeURIComponent(this.state.compressedList[this.state.queryList[index]]);
      }
      else{
        let axisIndex ;
        let personaIndex;
        for(let i = 0; i < filterData.persona_list.length; i++){
          for(let j = 0; j < 4; j++){
            if(filterData.persona_list[i].persona_filter_list[j].persona_filter_selected === 1){
              personaIndex = filterData.persona_list[i].persona_filter_list[j].persona_filter_id;
              axisIndex = filterData.persona_list[i].axis_id;
            }
            else{continue;}
          }
        }
        return `./#/persona-detail?page=1&s=${sex}&axis=`+ axisIndex +"&persona=" + personaIndex + "&filter=" + encodeURIComponent(this.state.compressedList[this.state.queryList[index]]);
      }
    }
    else{return undefined;}
  }

  /**
   * 保存条件ブロックを作成する(基本属性)
   * @param {object} data - 基本属性ブロック情報
   * @return {JSX} 基本属性のブロック名表示用HTMLを返す
   */
  basicStateGenerator = data => {
    if(data.basic_filter_list.length !== 0){
      for(let i = 0; i < data.basic_filter_list.length; i++){
        if(data.basic_filter_list[i].basic_filter_selected === 0){
          continue;
        }
        else{
          return(
            <div key={`basic_${data.basic_id}`}> 
              <b>{data.basic_name}</b>
              <div>{data.basic_filter_list.map(d => this.basicChildrenGenerator(d, data.basic_id))}</div>
            </div> 
          );
        }
      }
    }
  }

  /**
   * 保存条件項目を作成する(基本属性)
   * @param {object} data - 基本属性項目情報
   * @param {number} id - 基本属性id
   * @return {JSX} 基本属性の項目名表示用HTMLを返す
   */
  basicChildrenGenerator = (data, id) => {
    if(data.basic_filter_selected === 1){
      let basicId = "navi_1_" + data.basic_filter_id;
      return (
        <div 
          key={`basic_contents_${id}_${data.basic_filter_id}`}
          style={{display:"inline-block"}}
          className="filter"
          id={basicId}
        >{data.basic_filter_name}　　
        </div>
      );
    }
  }

  /**
   * 保存条件ブロックを作成する(ライフスタイル)
   * @param {object} data - ライフスタイルブロック情報
   * @return {JSX} ライフスタイルブロック名を表示するHTMLを返す
   */
  lifestyleStateGenerator = data => {
    if(data.lifestyle_filter_list.length !== 0){
      for(let i = 0; i < data.lifestyle_filter_list.length; i++){
        if(data.lifestyle_filter_list[i].lifestyle_filter_selected === 0){
          continue;
        }
        else{
          return(
            <div key={`life_${data.lifestyle_id}`}>
              <b>{data.lifestyle_name}</b>
              <div>{data.lifestyle_filter_list.map(d => this.lifestyleChildrenGenerator(d, data.lifestyle_id))}</div>
            </div> 
          );
        }
      }
    }
  }

  /**
   * 保存条件項目を作成する(ライフスタイル)
   * @param {object} data - ライフスタイル項目情報
   * @param {number} id - ライフスタイルid
   * @return {JSX} ライフスタイル項目名を表示するHTMLを返す
   */
  lifestyleChildrenGenerator = (data, id) => {
    if(data.lifestyle_filter_selected === 1){
      let lifeId = "navi_2_" + data.lifestyle_filter_id;
      return (
        <div 
          key={`life_contents_${id}_${data.lifestyle_filter_id}`}
          style={{display:"inline-block"}}
          className="filter"
          id={lifeId}
        >{data.lifestyle_filter_name}
        </div>
      );
    }
  }

  /**
   * 保存条件ブロックを作成する(ペルソナスケルトン)
   * @param {object} data - ペルソナスケルトンブロック情報
   * @return {JSX} ペルソナスケルトンブロック名を表示するHTMLを返す
   */
  personaStateGenerator = data => {
    if(data.persona_filter_list.length !== 0){
      for(let i = 0; i < data.persona_filter_list.length; i++){
        if(data.persona_filter_list[i].persona_filter_selected === 0){
          continue;
        }
        else{
          return(
            <div key={`persona_${data.axis_id}`}>
              <b>{data.axis_name}</b>
              <div>{data.persona_filter_list.map(d => this.personaChildrenGenerator(d, data.axis_id))}</div>
            </div> 
          );
        }
      }
    }
  }

  /**
   * 保存条件項目を作成する(ペルソナスケルトン)
   * @param {object} data - ペルソナスケルトン項目情報
   * @param {number} id - ぺルソナスケルトンid
   * @return {JSX} ペルソナスケルトン項目名を表示するHTMLを返す
   */
  personaChildrenGenerator = (data, id) => {
    if(data.persona_filter_selected === 1){
      let personaId = "navi_3_" + data.persona_filter_id;
      return (
        <div
          key={`persona_contents_${id}_${data.persona_filter_id}`}
          style={{display:"inline-block"}}
          className="filter"
          id={personaId}
        >{data.persona_filter_name}
        </div>
      );
    }
  }

  /**
   * 保存条件ブロックを作成する(購買行動)
   * @param {object} data - 購買行動ブロック情報
   * @return {JSX} 購買行動ブロック名を表示するHTMLを返す
   */
  brandStateGenerator = data => {
    if(data.purchasing_behavior_contents_filter_list.length !== 0){
      for(let i = 0; i < data.purchasing_behavior_contents_filter_list.length; i++){
        if(data.purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected === 0){
          continue;
        }
        else{
          return(
            <div key={`brand_${data.purchasing_behavior_id}`}>
              <b>{data.purchasing_behavior_name}</b>
              <div>{data.purchasing_behavior_contents_filter_list.map(d => this.brandChildrenGenerator(d, data.purchasing_behavior_id))}</div>
            </div> 
          );
        }
      }
    }
  }
  
  /**
   * 保存条件項目を作成する(購買行動)
   * @param {object} data - 購買行動項目情報
   * @param {number} id - 購買行動id
   * @return {JSX} 購買行動項目名を表示するHTMLを返す
   */
  brandChildrenGenerator = ( data, id )=> {
    if(data.purchasing_behavior_item_filter_selected === 1){
      let brandId = "navi_4_" + data.purchasing_behavior_contents_filter_id;
      return (
        <div 
          key={`brand_contents_${id}_${data.purchasing_behavior_contents_filter_id}`}
          style={{display:"inline-block"}}
          className="filter"
          id={brandId}
        >{data.purchasing_behavior_contents_filter_name}
        </div>
      );
    }    
    else if(data.purchasing_behavior_item_filter_selected === 2){
      let brandId = "navi_4_" + data.purchasing_behavior_contents_filter_id;
      let remove = "を除外";
      return (
        <div 
          key={`brand_contents_${id}_${data.purchasing_behavior_contents_filter_id}`}
          style={{cursor: "pointer",display:"inline-block"}}
          className="filterRemove"
          id={brandId}
        >{data.purchasing_behavior_contents_filter_name}{remove}
        </div>
      );
    }
  }

  /**
   * 閲覧可能データセットを表示する
   * @param d - データセット名
   * @param i - インデックス
   * @returns {JSX} 閲覧可能データセット表示用HTMLを返す
   */
  displayDataSet(d, i) {
    return(
      <tr key={i} className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
        <td><label className="ml-4">{d.label}</label></td>
      </tr>
    )
  }

  /**
   * 実際にウィンドウに表示する
   * @param {boolean} isDisable - ボタンをdisableにするかどうか
   * @param {string} cursor - マウスカーソルの種類
   * @param {string} ie11Alert - IE使用時の警告文
   * @param {string} ie11ClassName - class属性の値
  //  * @param {string} userAgent - ブラウザの種類
   * @param {JSON} queryData - JSON化した絞り込み条件
   * @param {object} rows - 保存条件一覧表の行情報
   * @param {object} columns - 保存条件一覧表の列情報
   * @param {object} columnExtensions - 保存条件一覧表の各列がソート対象かどうか
   * @return {JSX} 条件一覧テーブル、会員情報表示用HTMLを返す
   */
  render() {
    //Internet Explorerかどうか判定
    let isDisable, cursor, ie11Alert, ie11ClassName, excelPointer, excelDisable, trial_alt;
    let userAgent = window.navigator.userAgent.toLowerCase();
    let trial = localStorage.getItem("tr");
    let surveys = JSON.parse(localStorage.getItem("sy"));

    if(userAgent.indexOf('trident') !== -1) {
      //ページ表示ボタンをdisableにする
      isDisable = false;
      cursor = "pointer";
      ie11Alert = "";
      ie11ClassName="btn btn-secondary";
      // isDisable = true;
      // cursor = "default";
      // ie11Alert = "お使いのブラウザでは「ページ表示」「条件表示」ができません。推奨ブラウザをご利用ください。"
      // ie11ClassName="btn btn-secondary disabled";
      if(this.state.userData === "" || this.state.listOfFilterDataSavedByUser === undefined || this.state.compressedList === "" || this.state.queryList === ""){
        return (
          <div className="d-flex justify-content-center mt-4 pt-4">
            <span>Loading...</span>
          </div>
        );
      }
    }
    else{
      if(trial === "true"){
        isDisable = false;
        cursor = "pointer";
        excelPointer = "not-allowed";
        excelDisable = "auto";
        ie11Alert = "";
        ie11ClassName="btn btn-secondary";
        trial_alt = "トライアルユーザは使用できません";
      }
      else{
        isDisable = false;
        excelPointer = "pointer";
        excelDisable = "auto";
        cursor = "pointer";
        ie11Alert = "";
        ie11ClassName="btn btn-secondary";
        trial_alt = "";
      }

      if(this.state.userData === "" || this.state.listOfFilterDataSavedByUser === undefined || this.state.compressedList === "" || this.state.queryList === ""){
        return (
          <div className="d-flex justify-content-center mt-4 pt-4">
            <div className="spinner-border text-secondary" style={{width: "5rem", height: "5rem"}} role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        );
      }
    }
    if(this.state.listOfFilterDataSavedByUser.length !== 0){
      let queryData = JSON.parse(this.state.listOfFilterDataSavedByUser[this.state.selectedId].query);
      let i = -1;
      let rows =
        this.state.listOfFilterDataSavedByUser.map(data => {
          i++;
          if(data.page_label === "詳細ページ"){//詳細ページ
            return ({
              survey: data.survey, 
              savePage: data.page_label, 
              sex: data.sex === 1? <img alt="" src={man} width="40px" height="40px"/> :  <img alt="" src={woman} width="40px" height="40px"/>,
              saveName: data.name, 
              saveDate: data.created_at, 
              pageDisplay: <a className={ie11ClassName} role="button" rel="noopener noreferrer" href={this.pageDisplay.bind(this, data.query, data.sex, data.page_label, i)()} tabIndex="-1" aria-disabled="true" height="25px" size="sm" target="_blank">ページ表示</a>, 
              filterDisplay: <Button variant="secondary" onClick={this.dataDisp.bind(this, data.id)} style={{cursor: cursor}} disabled={isDisable} height="25px">条件表示</Button>, 
              report: <img alt={trial_alt} title={trial_alt} src={excels} onClick={() => setTimeout(this.exportReport.bind(this, data.id), 3000)} style={{cursor: excelPointer, pointerEvents: excelDisable}} width="40px" height="40px" id={data.id}/>,
              delete: <img alt="" src={dustbox} onClick={() => setTimeout(this.deleteData.bind(this, data.id, data.name), 3000)} style={{cursor: "pointer"}} width="40px" height="40px"/>
            });
          }
          else{
            return ({
              survey: data.survey, 
              savePage: data.page_label, 
              sex: data.sex === 1? <img alt="" src={man} width="40px" height="40px"/> :  <img alt="" src={woman} width="40px" height="40px"/>,
              saveName: data.name, 
              saveDate: data.created_at, 
              pageDisplay: <a className={ie11ClassName} role="button" rel="noopener noreferrer" href={this.pageDisplay.bind(this, data.query, data.sex, data.page_label, i)()} tabIndex="-1" aria-disabled="true" height="25px" size="sm" target="_blank">ページ表示</a>, 
              filterDisplay: <Button variant="secondary" onClick={this.dataDisp.bind(this, data.id)} style={{cursor: cursor}} disabled={isDisable} height="25px">条件表示</Button>, 
              report: "-",
              delete: <img alt="" src={dustbox} onClick={() => setTimeout(this.deleteData.bind(this, data.id, data.name), 3000)} style={{cursor: "pointer"}} width="40px" height="40px"/>
            });
          }
        });    
        const columns = [
          {name: "survey", title: "調査データ"}, 
          {name: "sex", title: "性別"}, 
          {name: "savePage", title: "保存元ページ"}, 
          {name: "saveName", title: "保存名"},
          {name: "saveDate", title: "保存日時"},
          {name: "pageDisplay", title: "ページ表示"},
          {name: "filterDisplay", title: "条件表示"},
          {name: "report", title: "帳票"},
          {name: "delete", title: "削除"}
        ];
        const columnExtensions = [
          { columnName: 'survey', sortingEnabled: false },
          { columnName: 'savePage', sortingEnabled: false },
          { columnName: 'sex', sortingEnabled: false },
          { columnName: 'saveName', sortingEnabled: true },
          { columnName: 'saveDate', sortingEnabled: true },
          { columnName: 'pageDisplay', sortingEnabled: false },
          { columnName: 'filterDisplay', sortingEnabled: false },
          { columnName: 'report', sortingEnabled: false },
          { columnName: 'delete', sortingEnabled: false }
        ];
        return　(
          <div>
          {/* 絞込条件表示モーダル */}
          <Modal
            size="sm"
            show={this.state.filterModalIsOpen}
            onHide={this.closeModal}
            centered
          >
            <Modal.Header className="filter-save-modal-header" style={{textAlign: "center"}}>
              <h5 style={{marginBottom: 0}}><b>絞り込み条件表示</b></h5>
            </Modal.Header>
            <Modal.Body className="filter-save-modal-content">   　　　　　　
              <div className="body" style={{overflowY: "scroll", width: "700px", height: "700px" }}>
                <div>{queryData.basic_list.map(d => this.basicStateGenerator(d))}</div>
                <div>{queryData.lifestyle_list.map(d => this.lifestyleStateGenerator(d))}</div>
                <div>{queryData.persona_list.map(d => this.personaStateGenerator(d))}</div>
                <div>{queryData.purchasing_behavior_list.map(d => this.brandStateGenerator(d))}</div>
              </div>
              <div className="py-3 px-3">
                <Button
                  variant="secondary"
                  onClick={this.closeModal}
                  className="mx-auto d-block"
                  size="sm"
                >
                  閉じる
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          {/* マイページ */}
          <Tabs>
            <TabList>
              <Tab>保存一覧</Tab>
              <Tab>会員情報</Tab>
              <Tab>システム情報</Tab>
            </TabList>
            <TabPanel>
            <p className="mx-4" style={{color: "red"}}>{ie11Alert}</p>
            <div className="mx-4">
              <Grid
                rows={rows}
                columns={columns}
              >
                <PagingState
                  currentPage={this.state.currentPage}
                  onCurrentPageChange={this.loadData.bind(this)}
                  // pageSize={process.env.REACT_APP_NUMBER_OF_QUERIES}
                  pageSize={10}
                />
                <CustomPaging totalCount={this.state.totalCount}/>
                <SortingState 
                  defaultSorting={[{ columnName: 'saveDate', direction: 'desc' }]}
                  columnExtensions={columnExtensions}
                />
                <IntegratedSorting />
                <TableTable/>
                <TableHeaderRow
                  showSortingControls
                  style={{color: "#f5f5f5"}}
                />
                <PagingPanel />
              </Grid>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="mx-2">
                <Table size="xs">
                  <thead>
                    <tr>
                      <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center" style={{color: "black"}}>企業情報</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">企業名</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.name}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">企業名（フリガナ）</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.name_f}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">郵便番号</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.postalcode}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">都道府県</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.pref}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">所在地</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.address}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">所在地（フリガナ）</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.address_f}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table size="xs">
                  <thead>
                    <tr>
                      <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center" style={{color: "black"}}>契約情報</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">プラン種別</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.plan_name}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">初回申込日</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.plan_st}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">契約満了日</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.company.plan_ed}
                        </td>
                      </tr>
                    </tbody>
                  </Table>

                  <Table size="xs">
                  <thead>
                    <tr>
                      <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center" style={{color: "black"}}>担当者情報</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                    <tbody>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">事業所名</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.office}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">担当者名</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.fullname}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">担当者名（フリガナ）</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.fullname_f}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">担当者部署</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.department}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">担当者役職</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.position}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">電話番号</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {this.state.userData.tel}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">メールアドレス</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          <a href={`mailto:${this.state.userData.email}`}>
                            {this.state.userData.email}
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                
                  <Table size="xs">
                    <thead>
                      <tr>
                        <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center" style={{color: "black"}}>閲覧可能データセット</div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                      <tbody>
                    {surveys.map((d, i) => this.displayDataSet(d, i))}
                  </tbody>
                </Table>

                </div>
                <div style={{textAlign: "right"}}>
                  <Button
                    variant="secondary"
                    onClick={this.changePage.bind(this)}
                    size="sm"
                    className="mb-4"
                  >
                    戻る
                  </Button>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="mx-4">
                  <Table>
                    <thead>
                      <tr>
                        <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                          <div className="d-flex justify-content-between">
                            <div className="d-flex align-items-center" style={{color: "black"}}>システム情報</div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                          <b className="ml-4">バージョン</b>
                        </td>
                        <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                          {`${process.env.REACT_APP_SYSTEM_VERSION}`}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
            </TabPanel>
            </Tabs>
          </div>     
        );
      }
      else if(this.state.listOfFilterDataSavedByUser.length === 0){
        let rows = [];
        const columns = [
          {name: "survey", title: "調査データ"}, 
          {name: "sex", title: "性別"}, 
          {name: "savePage", title: "保存元ページ"}, 
          {name: "saveName", title: "保存名"},
          {name: "saveDate", title: "保存日時"},
          {name: "pageDisplay", title: "ページ表示"},
          {name: "filterDisplay", title: "条件表示"},
          {name: "report", title: "帳票"},
          {name: "delete", title: "削除"}
        ];
        const columnExtensions = [
          { columnName: 'survey', sortingEnabled: false },
          { columnName: 'savePage', sortingEnabled: false },
          { columnName: 'sex', sortingEnabled: true },
          { columnName: 'saveName', sortingEnabled: true },
          { columnName: 'saveDate', sortingEnabled: true },
          { columnName: 'pageDisplay', sortingEnabled: false },
          { columnName: 'filterDisplay', sortingEnabled: false },
          { columnName: 'report', sortingEnabled: false },
          { columnName: 'delete', sortingEnabled: false }
        ];
      return　(
        <div style={{height: "840px", backgroundColor: "#f5f5f5"}}>
        {/* マイページ */}
        <Tabs>
          <TabList>
            <Tab>保存一覧</Tab>
            <Tab>会員情報</Tab>
            <Tab>システム情報</Tab>
          </TabList>
          <TabPanel style={{backgroundColor: "#f5f5f5"}}>
          <p className="mx-4" style={{color: "red"}}>{ie11Alert}</p>
          <div className="mx-4">
            <Grid
              rows={rows}
              columns={columns}
            >
              <PagingState
                currentPage={this.state.currentPage}
                onCurrentPageChange={this.loadData.bind(this)}
                // pageSize={process.env.REACT_APP_NUMBER_OF_QUERIES}
                pageSize={10}
              />
              <CustomPaging totalCount={this.state.totalCount}/>
              <SortingState 
                defaultSorting={[{ columnName: 'saveDate', direction: 'desc' }]}
                columnExtensions={columnExtensions}
              />
              <IntegratedSorting />
              <TableTable/>
              <TableHeaderRow
                showSortingControls
                style={{color: "#f5f5f5"}}
              />
              <PagingPanel />
            </Grid>
            {this.state.loading &&   
              <div className="loading-shading">
                <div className="loading-icon">
                  <span className="oi oi-loop-circular" />
                </div>
              </div>
            }
            </div>
          </TabPanel>
          <TabPanel style={{backgroundColor: "#f5f5f5"}}>
            <div className="mx-2">
              <Table size="xs">
                <thead>
                  <tr>
                    <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center" style={{color: "black"}}>企業情報</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">企業名</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.name}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">企業名（フリガナ）</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.name_f}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">郵便番号</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.postalcode}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">都道府県</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.pref}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">所在地</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.address}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">所在地（フリガナ）</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.address_f}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Table size="xs">
                <thead>
                  <tr>
                    <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center" style={{color: "black"}}>契約情報</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">プラン種別</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.plan_name}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">初回申込日</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.plan_st}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">契約満了日</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.company.plan_ed}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Table size="xs">
                <thead>
                  <tr>
                    <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center" style={{color: "black"}}>担当者情報</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">事業所名</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.office}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">担当者名</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.fullname}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">担当者名（フリガナ）</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.fullname_f}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">担当者部署</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.department}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">担当者役職</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.position}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">電話番号</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {this.state.userData.tel}
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">メールアドレス</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        <a href={`mailto:${this.state.userData.email}`}>
                          {this.state.userData.email}
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                                
                <Table size="xs">
                <thead>
                  <tr>
                    <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center" style={{color: "black"}}>閲覧可能データセット</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                  <tbody>
                    {surveys.map((d, i) => this.displayDataSet(d, i))}
                  </tbody>
                </Table>

              </div>
              <div style={{textAlign: "right"}}>
                <Button
                  variant="secondary"
                  onClick={this.changePage.bind(this)}
                  size="sm"
                  className="mb-4"
                >
                  戻る
                </Button>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="mx-4">
                <Table>
                  <thead>
                    <tr>
                      <th colSpan="2" className="py-2 px-2" style={{backgroundColor: "#f5f5f5"}}>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex align-items-center" style={{color: "black"}}>システム情報</div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="py-2 px-2 w-25" style={{ verticalAlign: "middle" }}>
                        <b className="ml-4">バージョン</b>
                      </td>
                      <td className="py-2 px-2" style={{ verticalAlign: "middle" }}>
                        {`${process.env.REACT_APP_SYSTEM_VERSION}`}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </TabPanel>
          </Tabs>
          
        </div>
        
      );          
    }
  }
}

export default MyPage;
