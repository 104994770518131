import React from "react";
import "./App.css";
import pic from "./images/icons/pic_g.png";
import { Button, Modal } from "react-bootstrap";

/**
 * プリファレンスマップ描画クラス
 */
class MultivariateAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      prefModalIsOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  /**
   * プリファレンスマップモーダルを開く
   */
  openModal() {
    this.setState({prefModalIsOpen: true}); 
  }

  /**
   * プリファレンスマップモーダルを閉じる
   */
  closeModal() {
    this.setState({ prefModalIsOpen: false });
  }


  /**
   * 実際にウィンドウに表示する
   * @param {string} disableTrial - トライアルユーザの機能制限
   * @return {JSX} プリファレンスマップ描画用HTMLを返す
   */
  render() {
    if(this.props.prefImage === undefined || this.props.prefImage === ""){
      return (
        <div className="d-flex justify-content-center mt-4 pt-4">
          <div className="spinner-border text-secondary" style={{width: "5rem", height: "5rem"}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
    let disableTrial;
    if(localStorage.getItem("tr") === "true"){
      disableTrial = "none";
    }
    else{
      disableTrial = "auto";
    }
    return　(
      <div>
        <Modal 
          show={this.state.prefModalIsOpen}
          onHide={this.closeModal}
          dialogClassName="modal-60w"
        >
          <Modal.Header className="py-3"/>
          <Modal.Body>
            <div className="ml-auto" style={{textAlign: "right", marginTop: "29px"}}>
              <a title="画像を保存" href={this.props.prefImage} download="プリファレンスマップ.png" style={{pointerEvents: disableTrial}}>
                <img 
                  src={pic} 
                  width="20px" 
                  height="20px" 
                  onClick={this.pic}
                  style={{cursor: "pointer", verticalAlign: "top"}}
                  alt=""
                />
              </a>
            </div>
            <div style={{textAlign: "center"}}>
              <img 
                src={this.props.prefImage} 
                alt=""
                width="700px" 
                height="698px" 
              />
            </div>
            <div className="py-2 d-flex">
              <Button
                variant="secondary"
                onClick={this.closeModal}
                className="ml-auto d-block"
                size="sm"
              >
                閉じる
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <div style={{textAlign: "center"}} className="d-flex flex-column mb-2">
          <b style={{fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000"}} className="mb-2">プリファレンスマップ</b>
            <div>
              <img 
                src={this.props.prefImage} 
                alt=""
                width="700px" 
                height="660px" 
                onClick={this.openModal}
                style={{cursor: "pointer"}}
              />
            </div>
          </div> 
        </div>
      )
    }
  }

export default MultivariateAnalysis;
