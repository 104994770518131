import React from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import "./App.css";
import filter from "./images/icons/filter.png";
import selector from "./images/icons/selector.png";
import analyze from "./images/icons/analyze_gray.png";
import pic from "./images/icons/pic_g.png";
import { concat } from "plotly.js/dist/plotly-cartesian";

/**
 * 購買行動描画クラス
 */
class BrandTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switchModalIsOpen: false,
      filterModalIsOpen: false,
      mapModalIsOpen: false,
      selectedModalId: 1,
      filterId: 1,
      responseBrand: "",  
      requestBrand: "",
      displayBrand: "",
      displayOnlyBrand: "",
      prevDisplayList: "",
      pmapImage: analyze,
      prevDisplayBrandList: "",
      modalDisplayBrand: "",
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.openModalBrand = this.openModalBrand.bind(this);
    this.allCheckOr = this.allCheckOr.bind(this);
    this.allCheckNot = this.allCheckNot.bind(this);
    this.filterDefaultChecked = this.filterDefaultChecked.bind(this);
    this.stateChange = this.stateChange.bind(this);
    this.defaultCheckedAxis = this.defaultCheckedAxis.bind(this);
    this.brandSwitchGenerator = this.brandSwitchGenerator.bind(this);
    this.nameSwitcher = this.nameSwitcher.bind(this);
    this.brandTableGenerator = this.brandTableGenerator.bind(this);
    this.checkboxSwitcher = this.checkboxSwitcher.bind(this);
    this.pmapGenerator = this.pmapGenerator.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setData = this.setData.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.closeSwitchModal = this.closeSwitchModal.bind(this);
    this.allUnCheckSwitchbox = this.allUnCheckSwitchbox.bind(this);
  }

  /**
   * ポジショニングマップを取得する
   * @param {number} survey - 調査名
   * @param {number} order - 選択された購買行動order
   * @param {number} contents - 選択された購買行動要素id
   * @param {number} zeroPaddedBrand - ゼロパディング済の選択された購買行動id
   * @param {number} zeroPaddedContents - ゼロパディング済の選択された購買行動要素id
   * @param {number} src - 選択された購買行動ポジショニングマップ画像
   */
  pmapGenerator(survey, order, contents){
    let zeroPaddedOrder = ( '00' + order ).slice( -2 );
    let zeroPaddedContents = ( '00' + contents ).slice( -2 );
    let male = Number(sessionStorage.getItem("se")) === 1? "_m" : ""; 
    let src = `${process.env.PUBLIC_URL}/images/pmap/pmap_${survey}${male}_${zeroPaddedOrder}_${zeroPaddedContents}.png`;
    this.setState({pmapImage: src});
  }

  /**
   * (React組み込み関数)絞り込み条件の変更有無が分かる
   * ※Navi.jsにshouldComponentUpdate()の公式URLを記載
   * @param {Array} nextProps - 次に更新される絞り込み結果
   * @return {boolean} 変更有無
   */
  shouldComponentUpdate(nextProps) {
    return !(this.state.requestBrand === nextProps.requestBrand);
  }

  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {Array} brand_list - 絞り込みのリクエスト
   * @param {Array} re_brand_list - 絞り込みのレスポンス
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   * @param {Array} displayResponseList - 画面表示させる10項目のレスポンスを格納
   * @param {number} count - 画面表示させる10項目カウント用変数 
   * @param {Array} displayList - 画面表示させる10項目のidを格納
   */
  UNSAFE_componentWillMount(){
    let brand_list = this.props.requestBrand;
    let re_brand_list = this.props.responseBrand;
    let modal_display_list = JSON.parse(JSON.stringify(this.props.responseBrand));
    //レスポンスpropsをorder順にソート
    re_brand_list.sort((a,b) => {
      if(a.purchasing_behavior_order < b.purchasing_behavior_order) return -1;
      if(a.purchasing_behavior_order > b.purchasing_behavior_order) return 1;
      return 0;
    });
    //モーダル表示用にレスポンスpropsをorder順にソート
    modal_display_list.sort((a,b) => {
      if(a.purchasing_behavior_order < b.purchasing_behavior_order) return -1;
      if(a.purchasing_behavior_order > b.purchasing_behavior_order) return 1;
      return 0;
    });
    //モーダル表示用にレスポンスpropsをさらにidで並べ替え
    for(let i = 0; i < modal_display_list.length; i++){   
      modal_display_list[i].purchasing_behavior_contents_list.sort((a, b) => {  
        if(a.purchasing_behavior_contents_id > b.purchasing_behavior_contents_id) return 1;
        if(a.purchasing_behavior_contents_id < b.purchasing_behavior_contents_id) return -1;
        return 0;
      });
    }
    //初期描画時のみidでソート
    //特化係数が0かどうかで判断
    let defaultSort = true;
    for(let i = 0; i < re_brand_list[0].purchasing_behavior_contents_list.length; i++){
      if(re_brand_list[0].purchasing_behavior_contents_list[i].specialization_coef !== 0){
        defaultSort = false;
      }
      else{continue;}
    }
    if(defaultSort){
    //   //レスポンスpropsをさらにidで並べ替え
      for(let i = 0; i < re_brand_list.length; i++){   
        re_brand_list[i].purchasing_behavior_contents_list.sort((a, b) => {  
          if(a.purchasing_behavior_contents_id > b.purchasing_behavior_contents_id) return 1;
          if(a.purchasing_behavior_contents_id < b.purchasing_behavior_contents_id) return -1;
          return 0;
        });
      }
    }
    else{
      //レスポンスpropsをさらに特化係数で並べ替え
      for(let i = 0; i < re_brand_list.length; i++){   
        re_brand_list[i].purchasing_behavior_contents_list.sort((a, b) => {  
          if(a.specialization_coef > b.specialization_coef) return -1;
          if(a.specialization_coef < b.specialization_coef) return 1;
          return 0;
        });
      }
    }
    //レスポンスのソートに合わせてリクエストpropsをソート
    let requestList = [];
    for(let i = 0; i < re_brand_list.length; i++){
      for(let j = 0; j < brand_list.length; j++){
        if(re_brand_list[i].purchasing_behavior_id === brand_list[j].purchasing_behavior_id){
          requestList.push(brand_list[j]);
        }
      }
    }
    this.setState({
      modalDisplayBrand: modal_display_list,
      responseBrand: re_brand_list,
      requestBrand: requestList,
      prevDisplayBrandList: requestList,
    });
    //表示用10個だけのリストを作成
    let displayResponseList = [];
    if(!this.props.brandDisplayList){
      for(let i = 0; i < 10; i++){
        displayResponseList.push(re_brand_list[i])
      }
    }
    else{
      for(let i = 0; i < this.props.brandDisplayList.length; i++){
        for(let j = 0; j < re_brand_list.length; j++){
          if(re_brand_list[j].purchasing_behavior_id === this.props.brandDisplayList[i]){
            displayResponseList.push(re_brand_list[j].purchasing_behavior_id);
          }
          else{continue;}
        }
      }
    }
    //表示させる10のブランドのindex10個を更新する
    //表示切替保存以前の復元に対応させる
    this.setState({
      displayBrand: displayResponseList,
      displayOnlyBrand: displayResponseList,
      prevDisplayList: displayResponseList,
      // prevDisplayList: this.props.brandPrevDisplayList,
    });
  }

  /**
   * (React組み込み関数)絞り込み結果が更新された時、画面表示用にデータを整理する
   * ※Top.jsにcomponentDidUpdate()の公式URLを記載
   * @param {Array} prevProps - 今までの絞り込み結果
   * @param {Array} brand_list - 絞り込みのリクエスト
   * @param {Array} re_brand_list - 絞り込みのレスポンス
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   * @param {Array} displayResponseList - 画面表示させる10項目のレスポンスを格納
   * @param {number} count - 画面表示させる10項目カウント用変数 
   */
  componentDidUpdate(prevProps){
    if(prevProps.responseBrand !== this.props.responseBrand){
      let brand_list = this.props.requestBrand;
      let re_brand_list = this.props.responseBrand;
      let modal_display_list = JSON.parse(JSON.stringify(this.props.responseBrand));
      //レスポンスpropsをorder順にソート
      re_brand_list.sort((a,b) => {
        if(a.purchasing_behavior_order < b.purchasing_behavior_order) return -1;
        if(a.purchasing_behavior_order > b.purchasing_behavior_order) return 1;
        return 0;
      });
      //モーダル表示用にレスポンスpropsをorder順にソート
      modal_display_list.sort((a,b) => {
        if(a.purchasing_behavior_order < b.purchasing_behavior_order) return -1;
        if(a.purchasing_behavior_order > b.purchasing_behavior_order) return 1;
        return 0;
      });
      //モーダル表示用にレスポンスpropsをさらにidで並べ替え
      for(let i = 0; i < modal_display_list.length; i++){   
        modal_display_list[i].purchasing_behavior_contents_list.sort((a, b) => {  
          if(a.purchasing_behavior_contents_id > b.purchasing_behavior_contents_id) return 1;
          if(a.purchasing_behavior_contents_id < b.purchasing_behavior_contents_id) return -1;
          return 0;
        });
      }
      //レスポンスpropsをさらに特化係数で並べ替え
      for(let i = 0; i < re_brand_list.length; i++){   
        re_brand_list[i].purchasing_behavior_contents_list.sort((a, b) => {  
          if(a.specialization_coef > b.specialization_coef) return -1;
          if(a.specialization_coef < b.specialization_coef) return 1;
          return 0;
        });
      }
      //レスポンスのソートに合わせてリクエストpropsをソート
      let requestList = [];
      for(let i = 0; i < re_brand_list.length; i++){
        for(let j = 0; j < re_brand_list.length; j++){
          if(re_brand_list[i].purchasing_behavior_id === brand_list[j].purchasing_behavior_id){
          requestList.push(brand_list[j].purchasing_behavior_id);
          }
        }
      }
      this.setState({
        modalDisplayBrand: modal_display_list,
        responseBrand: re_brand_list,
        requestBrand: requestList,
        prevDisplayBrandList: requestList,
      });
      //表示用10個だけのリストを作成
      let displayResponseList = [];
      let count = 0;
      re_brand_list.forEach(d => {
        if (count < this.state.displayBrand.length
          && this.state.displayBrand[count].purchasing_behavior_id === d.purchasing_behavior_id){
          displayResponseList.push(d.purchasing_behavior_id);
          count++;
        }
      });
      this.setState({
        displayBrand: displayResponseList,
        displayOnlyBrand: displayResponseList,
        prevDisplayList: displayResponseList,
        // prevDisplayList: this.props.brandPrevDisplayList,
      });
    }
  }

  /**
   * 絞り込みモーダルを開いた際の、モーダル内のチェック状態を確認する
   * @param {Array} currList - ユーザが操作した最新のチェック状態
   * @param {Array} prevList - 絞り込み時のチェック状態
   * @param {Array} sortedCurrList - currListのチェックされている項目idを格納
   * @param {Array} sortedPrevList - prevListのチェックされている項目idを格納
   */
  openModalBrand(){
    let currList = JSON.parse(JSON.stringify(this.state.requestBrand));
    let prevList = JSON.parse(JSON.stringify(this.state.prevDisplayBrandList));
    let sortedFilterCurrList = [];
    let sortedFilterPrevList = [];
    let sortedExceptCurrList = [];
    let sortedExceptPrevList = [];
    for(let i = 0; i < prevList.length; i++){
      if(currList[this.state.filterId].purchasing_behavior_id === prevList[i].purchasing_behavior_id){
        //「絞込」にチェックしてある
        currList[this.state.filterId].purchasing_behavior_contents_filter_list.filter(d => {
          if(d.purchasing_behavior_item_filter_selected === 1) return sortedFilterCurrList.push(d.purchasing_behavior_contents_filter_id);
          if(d.purchasing_behavior_item_filter_selected === 2) return sortedExceptCurrList.push(d.purchasing_behavior_contents_filter_id);
          return 0;
        });
        sortedFilterCurrList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        sortedExceptCurrList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        prevList[i].purchasing_behavior_contents_filter_list.filter(d => {
          if(d.purchasing_behavior_item_filter_selected === 1) return sortedFilterPrevList.push(d.purchasing_behavior_contents_filter_id);
          if(d.purchasing_behavior_item_filter_selected === 2) return sortedExceptPrevList.push(d.purchasing_behavior_contents_filter_id);
          return 0;
        });
        sortedFilterPrevList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        sortedExceptPrevList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        if(sortedFilterCurrList.toString() === sortedFilterPrevList.toString()){
          if(sortedExceptCurrList.toString() === sortedExceptPrevList.toString()){
            this.setState({prevDisplayBrandList: currList});
          }
          else if(sortedExceptCurrList.toString() !== sortedExceptPrevList.toString()){
            this.setState({requestBrand: prevList});
          }
        }
        //絞り込みボタンが押されなかったけどユーザがチェックを操作した後モーダルが閉じられた
        else if(sortedFilterCurrList.toString() !== sortedFilterPrevList.toString()){
          this.setState({requestBrand: prevList});
        }
      }
    }
  }

  /**
   * Top.jsまたはpersonaDetail.js(親)が持つ絞り込み条件を更新する
   * @param {Array} requestData - 購買行動テーブル内での最新の絞り込み条件
   */
  setData(){
    let data = JSON.parse(JSON.stringify(this.state.requestBrand));
    let requestData = data.map(d => {
      return d;
    });
    this.props.setRequestBrand(requestData);
    this.setState({prevDisplayBrandList: data});
    this.closeModal.bind(this)();
  }

  /**
   * ウィンドウに表示する購買行動ブロックを切り替えるために、10ブロック以上にチェックを入れようとしていないか確認する
   * @param {string} brandName - 表示切替項目名
   * @param {number} brandId - 表示切替項目id
   * @param {Array} displayList - テーブル内に表示しているライフスタイル項目リスト
   * @param {number} index - 選択された表示項目のインデックス
   */
  handleChange = (brandName, brandId) => {
    let displayList = [...this.state.displayBrand];
    let reposenseList = this.state.responseBrand;
      if(document.getElementById(brandName).checked === true){
        if(displayList.length > 9 ){
          document.getElementById(brandName).checked = false;
          alert("10項目までしか選択できません。チェックを減らしてください。");
          return false;
        }
        else{
          for(let i = 0; i < reposenseList.length; i++){
            //チェックされてないか確かめる
            if(reposenseList[i].purchasing_behavior_name === brandName){
              displayList.push(reposenseList[i].purchasing_behavior_id);
              break;
            }
          }
        }
      }
      //チェックが外れた
      else if(document.getElementById(brandName).checked === false){
          //brandIdがresponseBrandの何番目に格納されているか調べる
        for(let i = 0; i < this.state.responseBrand.length; i++){
          //表示1だったチェックが外された
          if(brandId === this.state.responseBrand[i].purchasing_behavior_id){
            //表示リストからIDを削除
            let index = displayList.indexOf(brandId);
            displayList.splice(index, 1);
          }
        }
        if(displayList.length !== 10){
          document.getElementById(brandName).checked = false;
        }
        // displayList.sort((a, b) => {
        //   if(a.purchasing_behavior_order < b.purchasing_behavior_order) return -1;
        //   if(a.purchasing_behavior_order > b.purchasing_behavior_order) return 1;
        //   return 0;
        // });
      }
      this.setState({displayBrand: displayList});
  }

  /**
   * 表示切替モーダルのチェック状態が、実際にウィンドウ表示されている購買行動ブロックと合うよう設定する
   * @param {number} brandId - 表示切替項目id
   * @return {boolean} チェック状態の真偽値
   */
  defaultCheckedAxis(brandId){
    let displayDefaultCheckedList = this.state.displayBrand;
    for(let j = 0; j < displayDefaultCheckedList.length; j++){
      if(displayDefaultCheckedList[j] === brandId){
        return true;
      }
      else{continue;}
    }
    return false;
  }

  /**
   * デフォルト操作を発生させない処理を行う
   * @param {Event} ev - イベント
   */
  handleSubmit(ev) {
    ev.preventDefault();
  }

  /**
   * 各種モーダルを開く
   * @param {string} modal - モーダルの種類
   * @param {number} isOpen - ブランドポジショニングマップ対応項目かどうかの値 
   */
  openModal(modal, isOpen) {
    if (modal === "filter") {
      if(localStorage.getItem("tr") === "true"){
        this.setState({ filterModalIsOpen: false });
      }
      else{
        this.setState({ filterModalIsOpen: true });
        this.openModalBrand.bind(this)();
      }
    }
    else if(modal === "switch"){
      if(localStorage.getItem("tr") === "true"){
        this.setState({ switchModalIsOpen: false });
      }
      else{
        this.setState({ switchModalIsOpen: true });
        // this.openModalBrandCate.bind(this)();
      }
    }
    else if(modal === "map"){
      if(!isOpen){
        return false;
      }
      else{
        this.setState({ mapModalIsOpen: true });
      }
    }

  }

  /**
   * 各種モーダルを閉じる
   */
  closeModal() {
    this.setState({
      filterModalIsOpen: false,
      switchModalIsOpen: false,
      mapModalIsOpen: false
    });
  }

/**
 * 表示切替モーダルを閉じる
 */
  closeSwitchModal(action) {
    let futureList = [...this.state.displayBrand];
    if(action === "display"){
      this.setState({
        displayOnlyBrand: futureList,
        displayBrand: futureList,
        prevDisplayList: futureList,
      })
      this.props.setDisplayBrand(futureList);
      this.setState({switchModalIsOpen: false});
    }
    else{
      let prevList = [...this.state.prevDisplayList]
        this.setState({
          displayBrand: prevList,
          switchModalIsOpen: false,
          displayOnlyBrand: prevList
        });
    }
  }

  /**
   * 「選択」の「全て選択」チェックボックスのクリックに応じて絞り込みモーダル内に表示されている除外チェックボックス全てにチェックを入れたり外したりする
   * @param {Array} requestList - 絞り込み結果のリクエスト
   */
  allCheckNot() {
    let requestList = JSON.parse(JSON.stringify(this.state.prevDisplayBrandList));
    for(let i = 0; i < requestList[this.state.filterId].purchasing_behavior_contents_filter_list.length; i++){
      for(let j = 0; j < requestList[this.state.filterId].purchasing_behavior_contents_filter_list.length; j++){
        let or = this.state.responseBrand[this.state.filterId].purchasing_behavior_contents_list[i].purchasing_behavior_contents_id + "_1";
        let not = this.state.responseBrand[this.state.filterId].purchasing_behavior_contents_list[i].purchasing_behavior_contents_id + "_2";
        if(this.state.responseBrand[this.state.filterId].purchasing_behavior_contents_list[i].purchasing_behavior_contents_id === requestList[this.state.filterId].purchasing_behavior_contents_filter_list[j].purchasing_behavior_contents_filter_id){
          //全解除チェックが付いている
          if(document.getElementById("notAll").checked === true){
            //全ての要素の除外欄にチェックを付ける
            requestList[this.state.filterId].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected = 2;
            document.getElementById(not).checked = true;
            document.getElementById(or).checked = false;
            //全選択チェックを外す
            document.getElementById("orAll").checked = false;
          }
          //全解除チェックが外れた
          else{
            requestList[this.state.filterId].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected = 0;
            //全てのチェックが外れる
            document.getElementById(not).checked = false;
            document.getElementById(or).checked = false;
            document.getElementById("orAll").checked = false;
          }
          this.setState({requestBrand: requestList});
        }
        else{continue;}
      }
    }
  }

  /**
   * 「除外」の「全て選択」チェックボックスのクリックに応じて絞り込みモーダル内に表示されている絞込チェックボックス全てにチェックを入れたり外したりする
   * @param {Array} requestList - 絞り込み結果のリクエスト 
   */
  allCheckOr() {
    let requestList = JSON.parse(JSON.stringify(this.state.prevDisplayBrandList));
    for(let i = 0; i < requestList[this.state.filterId].purchasing_behavior_contents_filter_list.length; i++){
      for(let j = 0; j < requestList[this.state.filterId].purchasing_behavior_contents_filter_list.length; j++){
        let or = this.state.responseBrand[this.state.filterId].purchasing_behavior_contents_list[i].purchasing_behavior_contents_id + "_1";
        let not = this.state.responseBrand[this.state.filterId].purchasing_behavior_contents_list[i].purchasing_behavior_contents_id + "_2";
        if(this.state.responseBrand[this.state.filterId].purchasing_behavior_contents_list[i].purchasing_behavior_contents_id === requestList[this.state.filterId].purchasing_behavior_contents_filter_list[j].purchasing_behavior_contents_filter_id){
          if(document.getElementById("orAll").checked === true){
            requestList[this.state.filterId].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected = 1;
            document.getElementById(or).checked = true;
            document.getElementById(not).checked = false;
            document.getElementById("notAll").checked = false;
          }
          else{
            requestList[this.state.filterId].purchasing_behavior_contents_filter_list[j].purchasing_behavior_item_filter_selected = 0;
            document.getElementById(or).checked = false;
            document.getElementById(not).checked = false;
            document.getElementById("notAll").checked = false;
          }
          this.setState({requestBrand: requestList});
        }
        else{continue;}
      }
    }
  }
  
  /**
   * 表示切替モーダルのチェックを全解除する
   */
  allUnCheckSwitchbox(){
    //全てのチェックを外す
    this.state.responseBrand.map(d => {
      document.getElementById(d.purchasing_behavior_name).checked = false;
    });
    //表示切替リストを空にする
    this.setState({displayBrand: []});
  }

  /**
   * 選択された購買行動idを更新する
   * @param {number} filter - 購買行動index
   */
  setFilter(filter, brandId){
    this.setState({filterId: filter});
    for(let i = 0; i < this.state.modalDisplayBrand.length; i++){
      if(this.state.modalDisplayBrand[i].purchasing_behavior_id === brandId){
      this.setState({selectedModalId: i});
      }    
      else{
        continue;
      }
    }
  }

  /**
   * 購買行動の表示切替を行う
   * @param {object} d - 購買行動ブロック情報
   * @return {JSX} 購買行動表示切替チェックボックスを返す
   */
  brandSwitchGenerator = (d, index) => {
    return(
      <span key={`filter_contents_${this.state.filterId}${d.purchasing_behavior_id}`}>
        <label>
          <input
            name="brand"
            id={d.purchasing_behavior_name}
            type="checkbox"
            onChange={this.handleChange.bind(this, d.purchasing_behavior_name, d.purchasing_behavior_id)}
            // defaultChecked={this.defaultCheckedAxis.bind(this, index)()}
            defaultChecked={this.defaultCheckedAxis.bind(this, d.purchasing_behavior_id)()}
            className="mx-2"
          />
          {d.purchasing_behavior_name}
        </label><br/>
      </span>
    );
  };

  /**
   * 絞り込みモーダル名を切り替える
   * @return {JSX} 購買行動名を返す
   */
  nameSwitcher(){
    return (
      <div>
        <div>
          <br/>
          <p>絞込&ensp;除外</p>
          <p>
            <label>
              <input
                className="checkboxOrAll ml-2 mr-4"
                id="orAll"
                type="checkbox"
                defaultChecked={false}
                onChange={this.allCheckOr.bind(this)}
              />
              <input
                className="checkboxNotAll mr-4 ml-2"
                id="notAll"
                type="checkbox"
                defaultChecked={false}
                onChange={this.allCheckNot.bind(this)}
              />
              全て選択
            </label>
          </p>
          <div className="body" style={{overflowY: "scroll", height: "500px" }}>
            {this.state.modalDisplayBrand[this.state.selectedModalId].purchasing_behavior_contents_list.map(x => this.checkboxSwitcher(x))}
          </div>
        </div>
      </div>
    );
  }

  /**
   * 表示切替チェックボックスを作成する
   * @param {object} d - 購買行動ブロック情報
   * @param {string} or - 購買行動項目絞込id
   * @param {string} not - 購買行動項目除外id
   * @return {JSX} 表示切替チェックボックス
   */
  checkboxSwitcher = d => {
    let or = d.purchasing_behavior_contents_id + "_1";
    let not = d.purchasing_behavior_contents_id + "_2";
    return(
      <span key={`filter_contents_${d.purchasing_behavior_contents_id}`}>
        <label>
          <input
            className="checkboxOr ml-2 mr-4"
            id={or}
            type="checkbox"
            defaultChecked={this.filterDefaultChecked.bind(this, or)()}
            onClick={this.stateChange.bind(this, or)}
          />            
          <input
            className="checkboxNot mr-4 ml-2"
            id={not}
            type="checkbox"
            defaultChecked={this.filterDefaultChecked.bind(this, not)()}
            onClick={this.stateChange.bind(this, not)}    
          />
          <div style={{display:"inline-block"}}>{d.purchasing_behavior_contents_name}</div>
        </label>
        <span className="badge badge-pill badge-secondary ml-2">{d.purchasing_behavior_contents_frequency}</span>
        <br/>
      </span>
    );
  }

  /**
   * クリックされた絞り込みチェックボックスのチェック状態を反転させる
   * @param {number} checkBoxId - 絞り込み項目id
   * @param {Array} requestList - 絞り込み結果のリクエスト
   * @param {string} or - 購買行動項目絞込id
   * @param {string} not - 購買行動項目除外id
   * @param {boolean} unCheckOr - 全選択チェックボックスのチェック状態
   * @param {boolean} unCheckNot - 全解除チェックボックスのチェック状態
   */
  stateChange(checkBoxId){
    let requestList = JSON.parse(JSON.stringify(this.state.requestBrand));
    for(let i = 0; i < requestList[this.state.filterId].purchasing_behavior_contents_filter_list.length; i++){
      let or = requestList[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_contents_filter_id + "_1";
      let not = requestList[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_contents_filter_id + "_2";
      if(or === checkBoxId){
        if(document.getElementById(or).checked === true){
          requestList[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected = 1;
          this.setState({requestBrand: requestList});
          document.getElementById(not).checked = false;
        }
        else{
          requestList[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected = 0;
          this.setState({requestBrand: requestList});
        }
      }
      else if(not === checkBoxId){
        if(document.getElementById(not).checked === true){
          requestList[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected = 2;
          this.setState({requestBrand: requestList});
          document.getElementById(or).checked = false;
        }
        else{
          requestList[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected = 0;
          this.setState({requestBrand: requestList});
        }
      }
    }
    // 全選択/全解除チェックボックスのチェック状態を確認する
    let unCheckOr = true;
    let unCheckNot = true;
    requestList[this.state.filterId].purchasing_behavior_contents_filter_list.forEach(d => {
      let or = d.purchasing_behavior_contents_filter_id + "_1";
      let not = d.purchasing_behavior_contents_filter_id + "_2";
      if(!document.getElementById(or).checked){
        unCheckOr = false;
      }
      if(!document.getElementById(not).checked){
        unCheckNot = false;
      }
    })
    // unCheckOr(unCheckNot)の値をそのままチェック状態とする
    // 項目全てにチェックが付いていた：unCheckOr(unCheckNot) = true
    // 項目どれかのチェックが外れていた：unCheckOr(unCheckNot) = false
    document.getElementById("orAll").checked = unCheckOr;
    document.getElementById("notAll").checked = unCheckNot;
  }

  /**
   * 絞り込み結果が更新された直後のチェック状態を再現する
   * @param {number} checkBoxId - 絞り込み項目id
   * @return {boolean} 真偽値を返す
   */
  filterDefaultChecked(checkBoxId){
    for(let i = 0; i < this.state.requestBrand[this.state.filterId].purchasing_behavior_contents_filter_list.length; i++){
      if(this.state.requestBrand[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_contents_filter_id + "_1" === checkBoxId
        && this.state.requestBrand[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected === 1){
        return true;
      }
      else if(this.state.requestBrand[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_contents_filter_id + "_2" === checkBoxId
        && this.state.requestBrand[this.state.filterId].purchasing_behavior_contents_filter_list[i].purchasing_behavior_item_filter_selected === 2){
        return true;
      }
    }
  }
  

  /**
   * 購買行動テーブルをアコーディオンにする(動的生成)
   * @param {object} d - 購買行動項目情報
   * @param {number} index - 購買行動項目index
   * @param {number} categoryNumber - 購買行動に該当する人数
   * @param {number} purchaseRate - 購買率
   * @param {string} decoration - 購買行動項目名下線の有無
   * @param {string} imgDisp - 購買行動項目名横のアイコンの有無
   * @param {string} cursor - 購買行動項目名マウスオーバー時のカーソルの種類
   * @return {JSX} 購買行動項目がアコーディオン表示になったものを返す
   */
   brandTableGenerator = (d, index) => {
    let categoryNumber = this.state.responseBrand[0].purchasing_behavior_total; 
    let purchaseRate;
    if(categoryNumber !== 0){
      purchaseRate = (Math.round((d.purchasing_behavior_contents_frequency / categoryNumber)*100*10)/10).toFixed(1);
    }
    else{
      purchaseRate = (0.0).toFixed(1);
    }
    let decoration;
    let imgDisp;
    let cursor;
    let tooltip;
    if(this.state.responseBrand[index].purchasing_behavior_graph){
      decoration = "underline";
      imgDisp = "inline";
      cursor = "pointer";
      tooltip = "ポジショニングマップへ移動する";
    }
    else{
      decoration = "none";
      imgDisp = "none";
      cursor = "default";
      tooltip = "";
    }
    return(
      <Row className="accordionDisp" key={`${index}_${d.purchasing_behavior_contents_id}`}>
        <Col lg={8} xl={8} xs={8}>                
          <div
            title={tooltip}
            style={{color : "gray", cursor: cursor, textDecoration: decoration}}
            onMouseUp={this.openModal.bind(this, "map", this.state.responseBrand[index].purchasing_behavior_graph)}
            onMouseDown={this.pmapGenerator.bind(this, this.state.responseBrand[index].survey_name, index, d.purchasing_behavior_contents_id)}
          >
            {d.purchasing_behavior_contents_name}
          <img src={analyze} alt="" width="10" height="10" style={{display: imgDisp}}/>
          </div>

        </Col>
        <Col lg={2} xl={2} xs={2} style={{textAlign: "end"}}>
          {purchaseRate}%
        </Col>
        <Col lg={2} xl={2} xs={2} style={{textAlign: "end"}}>
          {(Math.round(d.specialization_coef*100)/100).toFixed(2)}
        </Col>
      </Row>
    );
  };


  /**
   * 購買行動テーブルの項目トップ3を表示する(動的)
   * @param {number} index - 購買行動index
   * @param {number} categoryNumber - 購買行動に該当する人数
   * @param {number} purchaseRate1 - ランキング1位の購買率
   * @param {number} purchaseRate2 - ランキング2位の購買率
   * @param {number} purchaseRate3 - ランキング3位の購買率
   * @param {string} decoration - 購買行動項目名下線の有無
   * @param {string} imgDisp - 購買行動項目名横のアイコンの有無
   * @param {string} cursor - 購買行動項目名マウスオーバー時のカーソルの種類
   * @param {string} tooltip - 購買行動項目名マウスオーバー時のツールチップの表示内容
   * @return {JSX} 購買行動項目がアコーディオン表示になったものを返す
   */
   top3Generator(bIndex, index){
    let categoryNumber = this.state.responseBrand[0].purchasing_behavior_total;
    let purchaseRate1;
    let purchaseRate2;
    let purchaseRate3;  
    if(categoryNumber !== 0){
      purchaseRate1 = (Math.round((this.state.responseBrand[bIndex].purchasing_behavior_contents_list[0].purchasing_behavior_contents_frequency / categoryNumber)*100*10)/10).toFixed(1);
      purchaseRate2 = (Math.round((this.state.responseBrand[bIndex].purchasing_behavior_contents_list[1].purchasing_behavior_contents_frequency / categoryNumber)*100*10)/10).toFixed(1);
      purchaseRate3 = (Math.round((this.state.responseBrand[bIndex].purchasing_behavior_contents_list[2].purchasing_behavior_contents_frequency / categoryNumber)*100*10)/10).toFixed(1);
    }
    else{
      purchaseRate1 = (0.0).toFixed(1);
      purchaseRate2 = (0.0).toFixed(1);
      purchaseRate3 = (0.0).toFixed(1);
    }
    let decoration;
    let imgDisp;
    let cursor;
    let tooltip;
    if(this.state.responseBrand[[bIndex]].purchasing_behavior_graph){
      decoration = "underline";
      imgDisp = "inline";
      cursor = "pointer";
      tooltip = "ポジショニングマップへ移動する";
    }
    else{
      decoration = "none";
      imgDisp = "none";
      cursor = "default";
      tooltip = "";
    }
    return(
      <div>
        <div className="accordionDisp">
          <Col xs={8} className="contentsLeft my-1">                
            <div style={{color : "gray", cursor: cursor, textDecoration: decoration}} title={tooltip}>
              <b 
                onMouseUp={this.openModal.bind(this, "map", this.state.responseBrand[bIndex].purchasing_behavior_graph)} 
                onMouseDown={this.pmapGenerator.bind(this, this.state.responseBrand[bIndex].survey_name, bIndex, this.state.responseBrand[bIndex].purchasing_behavior_contents_list[0].purchasing_behavior_contents_id)} 
              >
                {this.state.responseBrand[bIndex].purchasing_behavior_contents_list[0].purchasing_behavior_contents_name}
              </b>
              <img src={analyze} alt="" width="10" height="10" style={{display: imgDisp}}/>
            </div>
          </Col>
          <label style={{textAlign: "right", cursor: "pointer"}} htmlFor={`toggleable${index+1}`}>
            <Col xs={2} className="contentsLeft my-1" style={{textAlign: "end"}}>
              {purchaseRate1}%
            </Col>
          </label>
          <label style={{textAlign: "right", cursor: "pointer"}} htmlFor={`toggleable${index+1}`}>
            <Col xs={2} className="contentsLeft my-1" style={{textAlign: "end"}}>
              {(Math.round(this.state.responseBrand[bIndex].purchasing_behavior_contents_list[0].specialization_coef*100)/100).toFixed(2)}
            </Col>
          </label>
        </div>
        <div className="accordionDisp">  
          <Col xs={8} className="contentsLeft my-1">                
            <div style={{color : "gray", cursor: cursor, textDecoration: decoration}} title={tooltip}>
              <b onMouseUp={this.openModal.bind(this, "map", this.state.responseBrand[bIndex].purchasing_behavior_graph)} 
              onMouseDown={this.pmapGenerator.bind(this, this.state.responseBrand[bIndex].survey_name, bIndex, this.state.responseBrand[bIndex].purchasing_behavior_contents_list[1].purchasing_behavior_contents_id)} >{this.state.responseBrand[bIndex].purchasing_behavior_contents_list[1].purchasing_behavior_contents_name}</b>
              <img src={analyze} alt="" width="10" height="10" style={{display: imgDisp}}/>
            </div>
            </Col>
           <label style={{textAlign: "right", cursor: "pointer"}} htmlFor={`toggleable${index+1}`}>
            <Col xs={2} className="contentsLeft my-1" style={{textAlign: "end"}}>
              {purchaseRate2}%
            </Col>
          </label>
          <label style={{textAlign: "right", cursor: "pointer"}} htmlFor={`toggleable${index+1}`}>
            <Col xs={2} className="contentsLeft my-1" style={{textAlign: "end"}}>
              {(Math.round(this.state.responseBrand[bIndex].purchasing_behavior_contents_list[1].specialization_coef*100)/100).toFixed(2)}
            </Col>
          </label>
        </div>
        <div className="accordionDisp">  
          <Col xs={8} className="contentsLeft my-1">                
            <div style={{color : "gray", cursor: cursor, textDecoration: decoration}} title={tooltip}>
              <b onMouseUp={this.openModal.bind(this, "map", this.state.responseBrand[bIndex].purchasing_behavior_graph)} 
              onMouseDown={this.pmapGenerator.bind(this, this.state.responseBrand[bIndex].survey_name, bIndex, this.state.responseBrand[bIndex].purchasing_behavior_contents_list[2].purchasing_behavior_contents_id)} >{this.state.responseBrand[bIndex].purchasing_behavior_contents_list[2].purchasing_behavior_contents_name}</b>
              <img src={analyze} alt="" width="10" height="10" style={{display: imgDisp}}/>
            </div>
            </Col>
          <label style={{textAlign: "right", cursor: "pointer"}} htmlFor={`toggleable${index+1}`}>
            <Col xs={2} className="contentsLeft my-1" style={{textAlign: "end"}}>
              {purchaseRate3}%
            </Col>
          </label>
          <label style={{textAlign: "right", cursor: "pointer"}} htmlFor={`toggleable${index+1}`}>
            <Col xs={2} className="contentsLeft my-1" style={{textAlign: "end"}}>
              {(Math.round(this.state.responseBrand[bIndex].purchasing_behavior_contents_list[2].specialization_coef*100)/100).toFixed(2)}
            </Col> 
          </label>
        </div>
          <label className="toggleablebrand__label" htmlFor={`toggleable${index+1}`}/>
      </div>
    );
  }



  /**
   * 実際にウィンドウに表示する
   * @param {boolean} trialSwitchDisable - トライアルユーザ機能制限(表示切替)
   * @param {boolean} trialDisable - トライアルユーザ機能制限(絞り込み)
   * @return {JSX} 購買行動テーブル・絞り込み/表示切替モーダル表示用HTMLを返す
   */
  render() {
    let trialSwitchDisable;
    let trialDisable;
    if(localStorage.getItem("tr") === "true"){
      trialDisable = "none";
      trialSwitchDisable = true;
    }
    else{
      trialSwitchDisable = false;
      trialDisable = "auto";
    }
    return (
      <div>
        {/* ポジショニングマップモーダル */}
        <Modal
          show={this.state.mapModalIsOpen} 
          onHide={this.closeModal}
          dialogClassName="modal-60w"
        >
          <Modal.Header className="py-4"/>
          <Modal.Body>
            <div>
              <div style={{textAlign: "right", marginTop: "10px", verticalAlign: "bottom"}}>
                <a title="画像を保存" href={this.state.pmapImage} download="ポジショニングマップ.png" style={{pointerEvents: trialDisable}}>
                  <img 
                    src={pic} 
                    className="ml-auto" 
                    width="20px" 
                    height="20px" 
                    style={{cursor: "pointer", verticalAlign: "top"}}
                    alt=""
                  />
                </a>
              </div>
              <img src={this.state.pmapImage} alt=""/>
              <div className="py-3 d-flex">
                ※対象範囲外で、一部非表示となっているプロットがあります。
                <Button
                  variant="secondary"
                  onClick={this.closeModal}
                  className="ml-auto d-block"
                  size="sm"
                >
                  閉じる
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        {/* 絞り込みモーダル */}
        <Modal 
          show={this.state.filterModalIsOpen}
          onHide={this.closeModal}
          dialogClassName="modal-50w"
        >
          <Modal.Header style={{textAlign: "center"}}>
            <h5 style={{marginBottom: 0}}><b>{this.state.responseBrand[this.state.filterId].purchasing_behavior_name}による絞り込み条件</b></h5>
          </Modal.Header>
          <Modal.Body>
            {this.nameSwitcher()}
            <div className="d-flex justify-content-center">
              <Button
                variant="secondary"
                onClick={this.setData}
                style={{ marginTop: 10, marginBottom: 10, marginRight: 10}}
                size="sm"
              >
                絞り込み
              </Button>
              <Button
                variant="secondary"
                onClick={this.closeModal}
                style={{marginTop: 10, marginBottom: 10}}
                size="sm"
                className="d-block"
              >
                閉じる
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {/* 表示切替モーダル */}
        <Modal 
          show={this.state.switchModalIsOpen}
          onHide={this.closeSwitchModal}
          dialogClassName="modal-40w"
        >
          <Modal.Header style={{textAlign: "center"}}>
            <h5 style={{marginBottom: 0}}><b>ブランド選択表示切替</b></h5>
          </Modal.Header>
          <Modal.Body>
            <div className="body" style={{overflowY: "scroll", height: "500px" }}>
              {this.state.responseBrand.map((x, index) => this.brandSwitchGenerator(x, index))}
            </div>
            <div className="d-flex justify-content-center">
              <p id="alert"></p><br/>
              <Button
                variant="secondary"
                onClick={this.allUnCheckSwitchbox}
                style={{marginTop: 10, marginBottom: 20}}
                size="sm"
                className="d-block"
              >
                すべてのチェックを外す
              </Button>
              <Button
                variant="secondary"
                id="change"
                // onClick={this.displayChange}
                onMouseUp={this.closeSwitchModal.bind(this, "display")}
                style={{ marginTop: 10, marginRight: 10, marginLeft: 10, marginBottom: 20 }}
                size="sm"
              >
                チェックした項目を表示する
              </Button>
              <Button
                variant="secondary"
                onClick={this.closeSwitchModal.bind(this, "close")}
                style={{marginTop: 10, marginBottom: 20}}
                size="sm"
                className="d-block"
              >
                閉じる
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        <div style={{textAlign: "right"}}>
          <Button
            variant="secondary"
            onClick={this.openModal.bind(this, "switch", 0)}
            className="mb-2"
            disabled={trialSwitchDisable}
            size="sm"
          >
            表示切替
            <img src={selector} alt="" width="15" height="15" className="ml-2 mb-1" />
          </Button>
        </div>
        {/* 表 */}
        <div className="px-2 scrollHandleBrand">
        {this.state.displayBrand.map((x, index) => {
          return(
            this.state.responseBrand.map((y, bIndex) => {
              if(x === y.purchasing_behavior_id){
                if(localStorage.getItem("tr") === "true"){
                  return (
                    <div key={bIndex} className="mb-1 mt-1" style={{textAlign: "center"}}>
                    <b style={{fontSize: "120%"}}>
                      <span
                        onMouseDown={this.setFilter.bind(this, bIndex, y.purchasing_behavior_id)}
                        onMouseUp={this.openModal.bind(this, "filter", index)}
                        style={{color : "gray"}}
                      >
                        {y.purchasing_behavior_name}
                      </span>
                    </b>
                    <div>
                      <div className="toggleablebrand" style={{borderTop: "3px solid gray"}}>
                        <input className="toggleablebrand__control hidden--visually hidden" id={`toggleable${index+1}`} type="checkbox" style={{display: "none"}}/>
                          {this.top3Generator(bIndex, index)}
                        <div className="toggleablebrand__content">
                          {y.purchasing_behavior_contents_list.map(z => this.brandTableGenerator(z, bIndex))}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              else{
                return(
                  <div key={bIndex} className="mb-1 mt-1" style={{textAlign: "center"}}>
                    <b style={{fontSize: "120%"}}>
                      <u
                        onMouseDown={this.setFilter.bind(this, bIndex, y.purchasing_behavior_id)}
                        onMouseUp={this.openModal.bind(this, "filter", index)}
                        style={{color : "gray", cursor: "pointer"}}
                      >
                        {y.purchasing_behavior_name}
                        <img src={filter} alt="" width="10" height="10"/>  
                      </u>
                    </b>
                    <div>
                      <div className="toggleablebrand" style={{borderTop: "3px solid gray"}}>
                        <input className="toggleablebrand__control hidden--visually hidden" id={`toggleable${index+1}`} type="checkbox" style={{display: "none"}}/>
                          {this.top3Generator(bIndex, index)}
                        <div className="toggleablebrand__content">
                          {y.purchasing_behavior_contents_list.map(z => this.brandTableGenerator(z, bIndex))}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            }
            })
          )
        })}
      </div>
     </div>
    );
  }
}
export default BrandTable;