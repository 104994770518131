import React from "react";
import { Button, Row, Col, Modal } from "react-bootstrap";
import histogram from "./images/icons/histogram.png";
import "./App.css";
import filter from "./images/icons/filter.png";
import createPlotlyComponent from 'react-plotlyjs';
import Plotly from 'plotly.js/dist/plotly-cartesian';
const PlotlyComponent = createPlotlyComponent(Plotly);

/**
 * 基本属性描画クラス
 */
class BasicPropertyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedId: 1,
      filterModalIsOpen: false,
      histogramModalIsOpen: false,
      requestBasic: "",
      responseBasic: "",
      prevBasic: "",
      modalY: 0,
    };
    this.defaultChecked = this.defaultChecked.bind(this);
    this.stateChange = this.stateChange.bind(this);
    this.allCheck = this.allCheck.bind(this);
    this.allUnCheck = this.allUnCheck.bind(this);
    this.setData = this.setData.bind(this);
    this.basicFormGenerator = this.basicFormGenerator.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.basicTableGenerator = this.basicTableGenerator.bind(this);
    this.histogramGenerator = this.histogramGenerator.bind(this);
    this.openHistogramModal = this.openHistogramModal.bind(this);
    this.openFilterModal = this.openFilterModal.bind(this);
    this.closeFilterModal = this.closeFilterModal.bind(this);
    this.closeHistogramModal = this.closeHistogramModal.bind(this);
    this.openModalBasic = this.openModalBasic.bind(this);
  }

  /**
   * (React組み込み関数)絞り込み結果の変更有無が分かる
   * ※Navi.jsにshouldComponentUpdate()の公式URLを記載
   * @param {object} nextProps - 次に更新される絞り込み結果
   * @return {boolean} 変更有無
   */
  shouldComponentUpdate(nextProps) {
    return !(this.state.requestBasic === nextProps.requestBasic);
  }

  /**
   * (React組み込み関数)初期画面表示のためにデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {Array} basic_list - 絞り込みのリクエスト
   * @param {Array} re_basic_list - 絞り込みのレスポンス
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   */
  UNSAFE_componentWillMount(){
    let basic_list = this.props.requestBasic;
    let re_basic_list = this.props.responseBasic;
    //レスポンスpropsをorder順にソート
    re_basic_list.sort((a,b) => {
      if(a.basic_order < b.basic_order) return -1;
      if(a.basic_order > b.basic_order) return 1;
      return 0;
    });
    //ソートに合わせてリクエストpropsをソート
    let requestList = [];
    for(let i = 0; i < re_basic_list.length; i++){
      for(let j = 0; j < re_basic_list.length; j++){
        if(re_basic_list[i].basic_id === basic_list[j].basic_id){
          requestList.push(basic_list[j]);
        }
        else{continue;}
      }
    }

    this.setState({
      requestBasic: requestList,
      responseBasic: re_basic_list,
      prevBasic: requestList
    });
  }

  /**
   * (React組み込み関数)絞り込み結果が更新された時、画面表示用にデータを整理する
   * ※Top.jsにcomponentDidUpdate()の公式URLを記載
   * @param {Array} prevProps - 今までの絞り込み結果
   * @param {Array} basic_list - 絞り込みのリクエスト
   * @param {Array} re_basic_list - 絞り込みのレスポンス
   * @param {Array} requestList - レスポンスの並び替え順に合わせて並び替えたリクエスト
   */
  componentDidUpdate(prevProps){
    if(prevProps.responseBasic !== this.props.responseBasic){
      let basic_list = this.props.requestBasic;
      let re_basic_list = this.props.responseBasic;
      //レスポンスpropsをそのままソート
      re_basic_list.sort((a,b) => {
        if(a.basic_order < b.basic_order) return -1;
        if(a.basic_order > b.basic_order) return 1;
        return 0;
      });
      //ソートに合わせてリクエストpropsをソート
      let requestList = [];
      for(let i = 0; i < re_basic_list.length; i++){
        for(let j = 0; j < re_basic_list.length; j++){
          if(re_basic_list[i].basic_id === basic_list[j].basic_id){
            requestList.push(basic_list[j]);
          }
          else{continue;}
        }
      }
      this.setState({
        requestBasic: requestList,
        responseBasic: re_basic_list,
        prevBasic: requestList
      });
    }
  }

  /**
   * 絞り込みモーダルを開いた際の、モーダル内のチェック状態を確認する
   * @param {Array} currList - ユーザが操作した最新のチェック状態
   * @param {Array} prevList - 絞り込み時のチェック状態
   * @param {Array} sortedCurrList - currListのチェックされている項目idを格納
   * @param {Array} sortedPrevList - prevListのチェックされている項目idを格納
   */
  openModalBasic(){
    let currList = JSON.parse(JSON.stringify(this.state.requestBasic));
    let prevList = JSON.parse(JSON.stringify(this.state.prevBasic));
    let sortedCurrList = [];
    let sortedPrevList = [];

    for(let i = 0; i < prevList.length; i++){
      if(currList[this.state.selectedId].basic_id === prevList[i].basic_id){
        sortedCurrList.push(currList[this.state.selectedId].basic_filter_list.filter(d => d.basic_filter_selected === 1));
        sortedCurrList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        sortedPrevList.push(prevList[i].basic_filter_list.filter(d => d.basic_filter_selected === 1));
        sortedPrevList.sort((a,b) => {  
          if(a < b) return -1;
          if(a > b) return 1;
          return 0;
        });
        //絞り込みボタンが押された
        if(sortedCurrList.toString() === sortedPrevList.toString()){
          this.setState({prevBasic: currList});
        }
        //絞り込みボタンが押されなかったけどユーザがチェックを操作した後モーダルが閉じられた
        else if(sortedCurrList.toString() !== sortedPrevList.toString()){
          this.setState({requestBasic: prevList});
        }
      }
    }
  }

  /**
   * Top.jsまたはpersonaDetail.js(親)の絞り込み条件を更新する
   * @param {Array} requestData - ライフスタイルテーブル内での最新の絞り込み条件
   */
  setData(){
    let requestData = JSON.parse(JSON.stringify(this.state.requestBasic));
    this.props.setRequestBasic(requestData);
    this.setState({prevBasic: requestData});
    this.closeFilterModal.bind(this)();
  }

  /**
   * 絞り込みチェックボックスを作成する
   * @param {object} d - 絞り込み項目情報
   * @param {boolean} trialDisable - トライアルユーザ機能制限
   * @return {JSX} チェックボックス付き絞り込み項目を返す
   */
  basicFormGenerator = d => {
    let trialDisable;
    if(localStorage.getItem("tr") === "true"){
      if(this.state.responseBasic[this.state.selectedId].basic_order === 0
        || this.state.responseBasic[this.state.selectedId].basic_order === 1){
        trialDisable = false;
      }
      else{
        trialDisable = true;
      }
    }
    else{
      trialDisable = false;
    }
    return (
      <span key={`filter_contents_${this.state.selectedId}${d.basic_distribution_id}`}>
        <label>
          <input
            name="basic"
            id={`basic_checkbox_${this.state.selectedId}${d.basic_distribution_id}`}
            value={d.basic_distribution_name}
            type="checkbox"
            defaultChecked={this.defaultChecked.bind(this, d.basic_distribution_id)()}
            onClick={this.stateChange.bind(this, d.basic_distribution_id)}
            disabled={trialDisable}
          />
          <div className="mx-2" style={{display:"inline-block"}}>{d.basic_distribution_name}</div>
        </label>
        <span className="badge badge-pill badge-secondary">{d.basic_distribution_frequency}</span>
        <br/>
      </span>
    );
  }

  /**
   * 絞り込み結果が更新された直後のチェック状態を再現する
   * @param {number} checkboxId - 絞り込み項目id
   * @return {boolean} 真偽値を返す
   */
  defaultChecked(checkboxId){
    for(let i = 0; i < this.state.requestBasic[this.state.selectedId].basic_filter_list.length; i++){
      if(this.state.requestBasic[this.state.selectedId].basic_filter_list[i].basic_filter_id === checkboxId
          && this.state.requestBasic[this.state.selectedId].basic_filter_list[i].basic_filter_selected === 0){
        return false;
      }
      else if(this.state.requestBasic[this.state.selectedId].basic_filter_list[i].basic_filter_id === checkboxId 
        && this.state.requestBasic[this.state.selectedId].basic_filter_list[i].basic_filter_selected === 1){
        return true;
      }
    }
  }

  /**
   * クリックされた項目が属するブロックidを更新する
   * @param {number} id - クリックされた項目id
   */
  handleChange(id){
    //orderの配列に並び替える
    for(let i = 0; i < this.state.responseBasic.length; i++){
      if(this.state.responseBasic[i].basic_id === id){
        this.setState({selectedId: i});
      }
      else{continue;}
    }
  }

  /**
   * クリックされた絞り込みチェックボックスのチェック状態を反転させる
   * @param {number} checkboxId - 絞り込み項目id
   * @param {Array} changedList - 絞り込み結果のリクエスト
   */
  stateChange(checkboxId){
    let changedList = JSON.parse(JSON.stringify(this.state.requestBasic));
    for(let i = 0; i < changedList[this.state.selectedId].basic_filter_list.length; i++){
      if(changedList[this.state.selectedId].basic_filter_list[i].basic_filter_id === checkboxId
        && changedList[this.state.selectedId].basic_filter_list[i].basic_filter_selected === 0){
          changedList[this.state.selectedId].basic_filter_list[i].basic_filter_selected = 1;
          this.setState({requestBasic: changedList});
      }
      else if(changedList[this.state.selectedId].basic_filter_list[i].basic_filter_id === checkboxId
        && changedList[this.state.selectedId].basic_filter_list[i].basic_filter_selected === 1){
          changedList[this.state.selectedId].basic_filter_list[i].basic_filter_selected = 0;
          this.setState({requestBasic: changedList});
      }
    }
  } 

  /**
   * 絞り込みモーダルを開く
   */
  openFilterModal() {
    this.setState({ filterModalIsOpen: true });
    this.openModalBasic.bind(this)();
  }

  /**
   * ヒストグラムモーダルを開く
   */
  openHistogramModal(){
    this.setState({ histogramModalIsOpen: true });
  }

  /**
   * 絞り込みモーダルを閉じる
   */
  closeFilterModal() {
    this.setState({filterModalIsOpen: false});
  }

  /**
   * ヒストグラムモーダルを閉じる
   */
  closeHistogramModal() {
    this.setState({histogramModalIsOpen: false});
  }

  /**
   * 絞り込みモーダル内に表示されている絞り込みチェックボックス全てにチェックを入れる
   * @param {Array} requestList - 絞り込み結果のリクエスト
   */
  allCheck() {
    let requestList = JSON.parse(JSON.stringify(this.state.prevBasic));
    for(let i = 0; i < requestList[this.state.selectedId].basic_filter_list.length; i++){
      for(let j = 0; j < requestList[this.state.selectedId].basic_filter_list.length; j++){
        if(this.state.responseBasic[this.state.selectedId].basic_distribution_list[i].basic_distribution_id === requestList[this.state.selectedId].basic_filter_list[j].basic_filter_id){
          requestList[this.state.selectedId].basic_filter_list[j].basic_filter_selected = 1;
          document.getElementById(`basic_checkbox_${this.state.selectedId}${requestList[this.state.selectedId].basic_filter_list[j].basic_filter_id}`).checked = true;
          this.setState({requestBasic: requestList});
        }
        else{continue;}
      }
    }
  }

  /**
   * 絞り込みモーダル内に表示されている絞り込みチェックボックス全てのチェックを外す
   * @param {Array} requestList - 絞り込み結果のリクエスト
   */
  allUnCheck() {
    let requestList = JSON.parse(JSON.stringify(this.state.prevBasic));
    for(let i = 0; i < requestList[this.state.selectedId].basic_filter_list.length; i++){
      for(let j = 0; j < requestList[this.state.selectedId].basic_filter_list.length; j++){
        if(this.state.responseBasic[this.state.selectedId].basic_distribution_list[i].basic_distribution_id === requestList[this.state.selectedId].basic_filter_list[j].basic_filter_id){
          requestList[this.state.selectedId].basic_filter_list[j].basic_filter_selected = 0;
          document.getElementById(`basic_checkbox_${this.state.selectedId}${requestList[this.state.selectedId].basic_filter_list[j].basic_filter_id}`).checked = false;
          this.setState({requestBasic: requestList});
        }
        else{continue;}
      }
    }
  }

  /**
   * ヒストグラムを作成する
   * @return {JSX} グラフを返す
   * @param {Array} name - 項目名を格納 
   * @param {Array} frequency - 項目該当者数を格納 
   * @param {number} positiveIndex - カテゴリ内で該当者数最大の配列のインデックス
   * @param {number} negativeIndex - カテゴリ内で該当者数最小の配列のインデックス
   * @param {number} positiveScore - カテゴリ内で該当者数最大値
   * @param {number} negativeScore - カテゴリ内で該当者数最小値
   * @param {Array} color - 各項目を表示するバーの色を格納
   * @param {Array} data - バー表示に必要なデータが格納
   * @param {object} config - バー表示に必要な設定
   * @param {object} layout - バー表示用レイアウト
   */
  histogramGenerator() {
    //グラフ要素(名称、値)をそれぞれ別の配列に格納
    let name = [], frequency = [];
    for(let i = 0; i < this.state.responseBasic[this.state.selectedId].basic_distribution_list.length; i++){
      name[i] = this.state.responseBasic[this.state.selectedId].basic_distribution_list[i].basic_distribution_name;
      frequency[i] = this.state.responseBasic[this.state.selectedId].basic_distribution_list[i].basic_distribution_frequency;
    }
    let positiveIndex = 0; 
    let negativeIndex = 0;
    let positiveScore = 0;
    let negativeScore = 0;
    for(let i = 0; i < frequency.length; i++){
      if(frequency[i] >= frequency[positiveIndex]){
        positiveIndex = i;
        positiveScore = frequency[i];
      }
      else{
        continue;
      }
    }
    for(let i = 0; i < frequency.length; i++){
      if(frequency[i] <= frequency[negativeIndex]){
        negativeIndex = i;
        negativeScore = frequency[i];
      }
      else{
        continue;
      }
    }
    //positiveRとnegativeR番目の要素に赤青、それ以外にグレーを入れた配列colorを作成
    let color = [];
    for(let i = 0; i < frequency.length; i++){
      if(frequency[i] === positiveScore){
        color[i] = "rgb(255, 20, 147)";
      }
      else if(frequency[i] === negativeScore){
        color[i] = "rgb(0, 0, 255)";
      }
      else{
        color[i] = "rgb(150, 150, 150)";
      }
    }
    let data = [
      {
        type: "bar",
        orientation: "h",
        y: name.reverse(),
        x: frequency.reverse(),
        width: 0.8,
        marker: {
          color: color.reverse()
        },
      }
    ];
    let config;
    let userAgent = window.navigator.userAgent.toLowerCase();
    // トライアルユーザかieでアクセスしていたら、カメラボタンを表示しない
    if(localStorage.getItem("tr") === "true" || userAgent.indexOf('trident') !== -1){
      config = {
        showLink: false,
        displayModeBar: true,
        scrollZoom: true,
        displaylogo: false,
        modeBarButtonsToRemove: [
          'lasso2d', 'autoScale2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian',
          'toggleSpikelines', 'select2d', 'zoom2d', 'pan2d', 'toImage'
        ],
      };
    }
    else{
      config = {
        showLink: false,
        displayModeBar: true,
        scrollZoom: true,
        displaylogo: false,
        modeBarButtonsToRemove: [
          'lasso2d', 'autoScale2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian',
          'toggleSpikelines', 'select2d', 'zoom2d', 'pan2d', 'toImage'
        ],
        modeBarButtonsToAdd: [
          {
          name: '画像の保存',
          icon: Plotly.Icons.camera,
          click: (gd) => {
            Plotly.downloadImage(gd, {format: 'png', width: 910, height: 450, filename: `${this.state.responseBasic[this.state.selectedId].basic_name}`})
          }
        },
      ]
      };
    }
    let layout = {
      yaxis: {
        automargin: true,
        tickangle: 0
      },
      title: {
        text: `<b>${this.state.responseBasic[this.state.selectedId].basic_name}</b>`,
        font: { size: 24 }
      },
    };
    return(
      <PlotlyComponent data={data} config={config} layout={layout} style={{width: "910px"}}/>
    );
  }

  /**
   * 基本属性テーブルを作成する
   * @param {object} d - 基本属性ブロック情報
   * @return {JSX} 基本属性テーブルを返す
   */
  basicTableGenerator = d =>{
    // 「ユーザが選択した項目を内部的に持つ」→「絞り込み/ヒストグラムモーダルを開く」の2ステップ必要なので
    // onMouseDown/onMouseUpを使う
    return(
      <Row className="accordionDisp" key={`${d.basic_id}_${d.basic_name}`}>
        <Col lg={5} xl={5} xs={5}>
          <b><u 
            height="25"
            onMouseUp={this.openFilterModal.bind(this)}
            onMouseDown={this.handleChange.bind(this, d.basic_id)}
            style={{color : "gray", cursor: "pointer"}}
          >
            {d.basic_name}
          </u></b>
          <img src={filter} alt="" width="10" height="10"/>
        </Col>
        <Col lg={5} xl={5} xs={5}>
          {d.basic_value}
        </Col>
        <Col lg={2} xl={2} xs={2} style={{textAlign: "right"}}>
          <Button 
            variant="outline-light"
            onMouseDown={this.handleChange.bind(this, d.basic_id)}
            onMouseUp={this.openHistogramModal.bind(this)}
            size="sm"
          >
            <img src={histogram} alt="" width="15" height="15"/>
          </Button>
        </Col>
      </Row>
    );
  }

  /**
   * 実際にウィンドウに表示する
   * @param {boolean} trialDisable - トライアルユーザの機能制限
   * @return {JSX} 基本属性・絞り込みグラフモーダル表示用HTMLを返す
   */
  render() {
    let trialDisable;
    if(localStorage.getItem("tr") === "true"){
      if(this.state.selectedId === 0 || this.state.selectedId === 1){
        trialDisable = false;
      }
      else{
        trialDisable =true;
      }
    }
    else{
      trialDisable = false;
    }
    return (
      <>
        {/* グラフ */}
        <Modal 
          show={this.state.histogramModalIsOpen}
          onHide={this.closeHistogramModal}
          dialogClassName="modal-50w"
        >
          <Modal.Header className="py-4"/>
          <Modal.Body>
            <div style={{marginTop: "2%", display: "flex", justifyContent: "center"}}>
              {this.histogramGenerator()}
            </div>
            <div className="my-2 mx-2">
              <Button
                variant="secondary"
                onClick={this.closeHistogramModal}
                className="ml-auto d-block"
                size="sm"
              >
                閉じる
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* 絞り込みモーダル */}
        <Modal 
          onHide={this.closeFilterModal} 
          show={this.state.filterModalIsOpen}
          dialogClassName="modal-30w"
        >
          <Modal.Header style={{textAlign: "center"}}>
            <h5 style={{marginBottom: 0}}><b>{this.state.responseBasic[this.state.selectedId].basic_name}による絞り込み条件</b></h5>
          </Modal.Header>
          <Modal.Body>
            <div className="body" style={{overflowY: "scroll", height: "500px"}}> 
              {this.state.responseBasic[this.state.selectedId].basic_distribution_list.map(x => (
                this.basicFormGenerator(x)
              ))}
            </div>
            <div className="d-flex justify-content-center">
            <Button
              variant="secondary"
              onClick={this.allCheck}
              size="sm"
              disabled={trialDisable}
              style={{marginTop: 10}}
            >
              全選択
            </Button>
            <Button
              variant="secondary"
              onClick={this.allUnCheck}
              style={{ marginLeft: 10, marginTop: 10}}
              disabled={trialDisable}
              size="sm"
            >
              全解除
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <Button
              variant="secondary"
              onClick={this.setData}
              style={{ marginTop: 10, marginBottom: 20, marginRight: 10, marginLeft: -10}}
              size="sm"
              disabled={trialDisable}
            >
              絞り込み
            </Button>
            <Button
                variant="secondary"
                onClick={this.closeFilterModal}
                style={{marginTop: 10, marginBottom: 20}}
                size="sm"
              >
                閉じる
              </Button>
          </div>
        </Modal.Body>
      </Modal>
       
       {/* テーブル */}
       <div>
        <div style={{textAlign: "right", marginLeft: "5%", marginRight: "5%"}} className="mt-2">
          {this.state.responseBasic.map(d => this.basicTableGenerator(d))}
        </div>
        </div>
      </>
    );
  }
}
export default BasicPropertyTable;