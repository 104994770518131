import React from "react";
import { TreeDataState } from "@devexpress/dx-react-grid";
import {Grid, Table, VirtualTable} from "@devexpress/dx-react-grid-bootstrap4";
import {SearchState, IntegratedFiltering, DataTypeProvider} from '@devexpress/dx-react-grid';
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import keywordmap from "./images/icons/keywordmap.png";

/**
 * 自由記述欄描画クラス
 */
class ReactGridTreeDataBinding extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      freeformList: "",
      formValue: "",
      compressedKey: "",
    };
    this.getWord = this.getWord.bind(this);
    this.setWord = this.setWord.bind(this);
    this.getData = this.getData.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * ※Top.jsにUNSAFE_componentWillMount()の公式URLを記載
   * @param {Array} re_freeform_list - 絞り込みのレスポンス
   */
  UNSAFE_componentWillMount(){
    let re_freeform_list = this.props.responseFreeform;
    this.getData.bind(this, re_freeform_list)();
    this.setWord.bind(this)();
  }

  /**
   * (React組み込み関数)画面描画が行われる度にデータを更新する
   * https://ja.reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
   * @param {Array} nextProps - 次に更新される絞り込み結果
   * @param {Array} re_freeform_list - 絞り込みのレスポンス
   */
  UNSAFE_componentWillReceiveProps(nextProps){
    let re_freeform_list = nextProps.responseFreeform;
    this.getData.bind(this, re_freeform_list)();
    this.setWord.bind(this)();
  }

  /**
   * 自由記述文絞込ワードを更新する
   */
  setWord(){
    if(this.props.keyWord !== undefined){
      this.setState({formValue: this.props.keyWord});
    }
    else if(this.state.formValue === undefined){
      this.setState({formValue: ""});
    }
    else{
      this.setState({formValue: ""});
    }
  }

  /**
   * 画面表示用に自由記述文リストを整形する
   * @param {Array} re_freeform_list - 自由記述リスト
   * @param {Array} freeformSentence - 自由記述文リスト
   */
  getData(re_freeform_list){
    let freeformSentence = [];
    const freeform = (_id, _name, _list) => {
      return {
        id: _id,
        name: _name,
        list: _list
      };
    };
    for(let i = 0; i < re_freeform_list[0].freeform_contents.length; i++){
      freeformSentence.push(re_freeform_list[0].freeform_contents[i]);
    }
    let freeformContents = freeform(
      re_freeform_list[0].freeform_id, 
      re_freeform_list[0].freeform_name, 
      freeformSentence
    );
    this.setState({freeformList: freeformContents});
  };

  /**
   * 検索ワードを検索処理に渡す
   * @return {string} 検索欄に入力してある文字列
   */
  getWord() {
    if(this.state.formValue !== undefined){
      return this.state.formValue;
    }
    else{
      return "";
    }
  };

  /**
   * 各種ページへ遷移する
   * @param {string} userAgent - 使用ブラウザ
   * @param {string} href - URLの#以降
   * @param {string} filterData - 文字列化した絞り込み条件
   * @param {string} url_multi - マップページURL
   */
  handleClick() {
    // //Internet Explorerかどうか判定
    // let userAgent = window.navigator.userAgent.toLowerCase();
    // if(userAgent.indexOf('trident') !== -1) {
    //   return false;
    // }
    if(window.location.hash.split("#/?filter=")[1] === undefined){
      let href = window.location.href;
      let compressed_key = this.props.compressedKey;
      return `${href}map?page=2&index=2&s=${Number(sessionStorage.getItem("se"))}&filter=${encodeURIComponent(compressed_key)}`;
    }
    else{
      if(this.props.compressedKey !== ""){
          let origin = window.location.origin;
          return `${origin}/#/map?page=2&index=2&s=${Number(sessionStorage.getItem("se"))}&filter=${encodeURIComponent(this.props.compressedKey)}`;
      }
      else{return undefined;}
    }
  }

  /**
   * 検索ワードを削除する
   */
  deleteValue(){
    this.setState({formValue: ""});
  }

  /**
   * 実際にウィンドウに表示する
   * @param {string} cursor - マウスカーソルの種類
   * @param {string} display - ボタンを表示するかどうか
   * @param {string} ie11ClassName - class属性の値を設定
   * @param {string} userAgent - ブラウザの種類
   * @param {string} hash - URLの#以降をデコードしたもの
   * @param {string} get - URLから絞り込み条件を切り取った文字列
   * @param {object} rows - 保存条件一覧表の行情報
   * @param {object} columns - 保存条件一覧表の列情報
   * @param {object} columnExtensions - 保存条件一覧表の各列がソート対象かどうか
   * @param {JSX} TooltipFormatter - 自由記述文のツールチップ表示内容
   * @param {JSX} CellTooltip - 入力文字によってフォーマットを変更
   * @param {object} tableColumnExtensions - 自由記述の表への追加情報
   * @return {JSX} 自由記述表示用HTMLを返す
   */
  render() {
    //Internet Explorerかどうか判定
    let cursor, display, ie11ClassName;
    // let userAgent = window.navigator.userAgent.toLowerCase();
    // if(userAgent.indexOf('trident') !== -1) {
    //   //ページ表示ボタンをdisableにする
    //   cursor = "not-allowed";
    //   ie11ClassName = "btn btn-secondary btn-sm ml-2 disabled";
    // }
    // else{
    cursor = "pointer";
    ie11ClassName = "btn btn-secondary btn-sm ml-2";
    // }
    let hash = decodeURIComponent(window.location.hash);
    let get = hash.split("#/")[1];
    if(get === undefined || get === ""){
      display = "block";
    }
    else if(hash.split("map")[1] !== undefined || hash.split("persona-detail")[1] !== undefined){
      display = "none";
    }
    else{
      if(hash.split("filter")[1] === undefined){
        display = "none";
      }
      else{
        display = "block";
      }
    }

    const columns = [{ name: "freeform", title: "自分らしさに関する自由記述" }];
    const columnExtensions = { columnName: '自分らしさ', sortingEnabled: false };
    let rows = 
      this.state.freeformList.list.map(x =>  {
          let row = { freeform : x};
        return row;
      });
    const tableColumnExtensions = [{ columsName: "freeform"}];
    if(localStorage.getItem("tr") === "false"){
      const formatter = ({ row: { freeform, }, value }) => (
        <u className="freeform-style" style={{textDecoration: "none"}}>
          {freeform}
        </u>
      );
      const Cell = props => (
        <DataTypeProvider
          for={columns.map(({ name }) => name)}
          formatterComponent={formatter}
          {...props}
        />
      );
      return (
        <div>
          <div className="d-flex justify-content-end">
            <div>
              <form>
                <input 
                  type="text" 
                  className="saveName"
                  onChange={e => {
                    if(e.target.value === undefined){
                      this.setState({formValue: ""});
                    }
                    else{
                      this.setState({formValue: e.target.value});
                    }
                  }} 
                  value={this.state.formValue} 
                  placeholder="Search..."
                />
                <svg xmlns="http://www.w3.org/2000/svg" onClick={this.deleteValue.bind(this)} width="16" height="16" fill="currentColor" className="bi bi-x-circle deleteValue" viewBox="0 0 16 16">
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </form>
            </div>
            <a 
              className={ie11ClassName}
              target="_blank"
              href={this.handleClick.bind(this)()} 
              role="button"
              style={{cursor: cursor, color: "#f5f5f5", display: display}}
              rel="noopener noreferrer"
            >
              KWマップ
              <img src={keywordmap} alt="" width="15" height="15" className="ml-1 mb-1"/>
            </a>
          </div>
          <div style={{marginLeft: "5%", marginRight: "5%", marginBottom: "5%"}} className="textPrint">
            <Grid rows={rows} columns={columns}>
              <SearchState value={this.getWord()} columnExtensions={columnExtensions}/>
              <Cell/>
              <IntegratedFiltering/>
              <TreeDataState/>
              <Table columnExtensions={tableColumnExtensions} style={{ textAlign: "right", verticalAlign: "middle"}}/>
              <VirtualTable/>
            </Grid>
          </div>
        </div>
      );
    }
    else{
      return (
        <div>
          <div className="d-flex justify-content-end">
            <form>
              <input 
                type="text" 
                disabled={true}
                title="トライアルユーザでは使用できません"
                className="saveName"
                value=""
                placeholder="Search..."
              />
            </form>
            <a 
              className={ie11ClassName}
              target="_blank"
              href={this.handleClick.bind(this)()} 
              role="button"
              style={{cursor: cursor, color: "#f5f5f5", display: display}}
              rel="noopener noreferrer"
            >
              KWマップ
              <img src={keywordmap} alt="" width="15" height="15" className="ml-1 mb-1"/>
            </a>
          </div>
          <div style={{marginLeft: "5%", marginRight: "5%", marginBottom: "5%"}} className="textPrint">
            <Grid rows={rows} columns={columns}>
              <TreeDataState/>
              <Table columnExtensions={tableColumnExtensions} style={{ textAlign: "right", verticalAlign: "middle"}}/>
              <VirtualTable/>
            </Grid>
          </div>
        </div>
      );
    }
  }
}

export default ReactGridTreeDataBinding;