import React from "react";
import { Redirect } from "react-router-dom";

/**
 * 認証処理クラス
 */
class Auth extends React.Component {
  /**
   * 認証処理を行う
   */
  isLoggedIn() {
    const item = localStorage.getItem("it");
    return item !== null;
  }

  /**
   * 実際にウィンドウに表示する
   * @return {JSX} ログイン画面表示用HTMLを返す
   */
  render() {
    return this.isLoggedIn() ? (
      this.props.children
    ) : (
      <Redirect to={"/Login"} />
    );
  }
}
export default Auth;
