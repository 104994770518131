import React from "react";
import "./App.css";
import { Form } from "react-bootstrap";
import Modal from "react-modal";
import axios from 'axios';
import { clearDS } from "./DataStore";

Modal.setAppElement("#root");
/**
 * ログイン画面描画クラス
 */
class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      new_password: "",
      new_password2: "",
      renewpassword: false,
      session: "",
      error_msg: "",
      info_msg: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  /**
   * ユーザ名とパスワードの文字数を確認する
   * @return {boolean} 真偽値を返す
   */
  validateForm() {
    return this.state.userName.length > 0 && this.state.password.length > 0;
  }

  /**
   * ログイン情報として入力された情報を更新する
   * @param {Event} ev - イベント
   */
  handleChange(ev) {
    this.setState({
      [ev.target.id]: ev.target.value
    });
  }

  componentDidMount() {
    document.title = 'ログイン|ペルソナキューブ';
  }

  /**
   * ログイン処理を行う
   * @param {Event} ev - イベント
   */
  handleSubmit(ev) {
    if (!this.state.renewpassword){
      // 通常のログイン
      axios.post(`${process.env.REACT_APP_API_ORIGIN}/api/v1/login`, {
        id: this.state.userName, pass: this.state.password,
        session: this.state.session, new_pass: this.state.new_password
      })
        .then((data) => {
          if (data.data.AuthenticationResult) {
            // 通常ログインの場合
            const idToken = data.data.AuthenticationResult.IdToken;
            const accessToken = data.data.AuthenticationResult.AccessToken;
            const refreshToken = data.data.AuthenticationResult.RefreshToken;
            const cognitoId = data.data.Username;
            const surveys = data.data.surveys;
            const selectedSurvey = data.data.selected_survey;

            // surveyをソートする
            // まずはlabelで年度ごとにソート
            surveys.sort((a,b) => parseInt(b.label) - parseInt(a.label));
            // そのあとnameの後ろ半分を見て1000,2000,5000,allにソート(逆の方がいいかも)
            // 年をまとめてからソートするのか、データ数をまとめてソートするのか要確認

            const str_surveys = JSON.stringify(surveys);
            const str_selected_survey = JSON.stringify(selectedSurvey);

            localStorage.setItem("it", idToken);
            localStorage.setItem("at", accessToken);
            localStorage.setItem("rt", refreshToken);
            localStorage.setItem("ci", cognitoId);
            localStorage.setItem("sy", str_surveys);
            localStorage.setItem("ss", str_selected_survey);
            sessionStorage.setItem("ss", str_selected_survey);
            localStorage.setItem("se", selectedSurvey.sex);
            sessionStorage.setItem("se", selectedSurvey.sex);
            clearDS();
            // トライアル対応
            localStorage.setItem("sv", data.data.survey_name);
            localStorage.setItem("tr", data.data.is_trial);
            localStorage.setItem("lt", new Date().getTime());
            window.open("../", "_self");
          } else {
            // 登録後初回ログイン、もしくはパスワードリセット後初回ログインの場合
            this.setState({
              renewpassword: true,
              error_msg: "初回パスワードを確認しました。パスワードを設定してください",
              session: data.data.Session
            });
  
          }
        })
        .catch(() => {
          console.log("error");
          this.setState({
            error_msg: "ユーザ名もしくはパスワードが間違っています。"
          })
        })  
    } else {
      if (this.state.new_password !== this.state.new_password2){
        console.log("error");
        this.setState({
          error_msg: "パスワードが異なります。同一のパスワードを入力してください。"
        })
      } else {
        axios.post(`${process.env.REACT_APP_API_ORIGIN}/api/v1/login`, {
          id: this.state.userName, pass: this.state.password,
          session: this.state.session, new_password: this.state.new_password
        })
          .then((data) => {
            if (data.data.AuthenticationResult) {
              // 通常ログインの場合
              const idToken = data.data.AuthenticationResult.IdToken;
              const accessToken = data.data.AuthenticationResult.AccessToken;
              const refreshToken = data.data.AuthenticationResult.RefreshToken;
              const cognitoId = data.data.Username;
              const surveys = data.data.surveys;
              const selectedSurvey = data.data.selected_survey;

              // surveyをソートする
              // まずはlabelで年度ごとにソート
              surveys.sort((a,b) => parseInt(b.label) - parseInt(a.label));
              // そのあとnameの後ろ半分を見て1000,2000,5000,allにソート(逆の方がいいかも)
              // 年をまとめてからソートするのか、データ数をまとめてソートするのか要確認

              const str_surveys = JSON.stringify(surveys);
              const str_selected_survey = JSON.stringify(selectedSurvey);
              localStorage.setItem("it", idToken);
              localStorage.setItem("at", accessToken);
              localStorage.setItem("rt", refreshToken);
              localStorage.setItem("ci", cognitoId);
              localStorage.setItem("sy", str_surveys);
              localStorage.setItem("ss", str_selected_survey);
              sessionStorage.setItem("ss", str_selected_survey);
              localStorage.setItem("se", selectedSurvey.sex);
              sessionStorage.setItem("se", selectedSurvey.sex);
              clearDS();
              // トライアル対応
              localStorage.setItem("sv", data.data.survey_name);
              localStorage.setItem("tr", data.data.is_trial);
              localStorage.setItem("lt", new Date().getTime());
              window.open("../", "_self");
            } else {
              this.setState({
                renewpassword: true,
                error_msg: "サーバエラーが発生しました。",
                session: data.data.Session
              });
    
            }
          })
          .catch(() => {
            console.log("error");
            this.setState({
              error_msg: "サーバエラーが発生しました。"
            })
          })    
      }
    }
    ev.preventDefault();
  }

  /**
   * 実際にウィンドウに表示する
   * @return {JSX} ログイン画面表示用HTMLを返す
   */
  render() {
    return (
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        textAlign: "left",
      }}>
      <div style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#f5f5f5"
      }}>
      <form style={{
          paddingTop: "40px", 
          paddingBottom: "40px",
          width: "100%",
          maxWidth: "450px",
          marginLeft: "38%"
          }}
          onSubmit={this.handleSubmit}
      >
      <h3>Login</h3>
      <div id="loginMessage" className="alert alert-warning" role="alert" style={{display: this.state.error_msg.length > 0 ? 'block' : 'none'}}>{this.state.error_msg}</div>

          <Form.Group controlId="userName"
          style={{ display: this.state.renewpassword ? 'none' : 'block' }}>
            <Form.Control
              autoFocus
              type="text"
              placeholder="User Name"
              className="mr-sm-2"
              value={this.state.userName}
              onChange={this.handleChange}
            />
          </Form.Group>

          <Form.Group controlId="password"
          style={{ display: this.state.renewpassword ? 'none' : 'block' }}>
            <Form.Control
              type="password"
              placeholder="Password"
              className="mr-sm-2"
              value={this.state.password}
              onChange={this.handleChange}
            />
            </Form.Group>

            <Form.Group controlId="new_password"
              style={{ display: this.state.renewpassword ? 'block' : 'none' }} >
              <Form.Control
                type="password"
                placeholder="New Password"
                className="mr-sm-2"
                value={this.state.new_password}
                onChange={this.handleChange}
              />
            </Form.Group>

            <Form.Group controlId="new_password2"
              style={{ display: this.state.renewpassword ? 'block' : 'none' }} >
              <Form.Control
                type="password"
                placeholder="New Password(Confirm)"
                className="mr-sm-2"
                value={this.state.new_password2}
                onChange={this.handleChange}
              />
            </Form.Group>

                <button type="submit" className="btn btn-primary btn-block">Submit</button>
                {/* <p className="forgot-password text-right">
                    Forgot <a href="#">password?</a>
                </p> */}
                <p className="mt-2" style={{fontSize: "70%", textAlign: "end"}}>
                  パスワードを忘れた場合は管理者にお問い合わせください。
                </p>
            </form>
          </div>
        </div>
    );
  }
}

export default Login;
