import React from "react";
import "./App.css";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Auth from "./Auth";
import Navi from "./Navi";
import Login from "./Login";
import Top from "./Top";
import PersonaDetail from "./PersonaDetail";
import MyPage from "./MyPage";
import Map from "./Map";
import TagManager from 'react-gtm-module';
// TagManager.initialize({ gtmId: "GTM-5QSLWK7" });
TagManager.initialize({ gtmId: `${process.env.REACT_APP_GA_TRACKING_ID}` });

/**
 * Appクラス
 */
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listOfFilterInTop: "",
      listOfFilterInPersona: "",
      listOfReFilterInTop: "",
      listOfFilterDataSavedByUser: "",
      listOfReFilterInPersona: "",
      warnText: "",
      warnColor: "blue",
      // survey: 2019,
    };
    this.setFilterState = this.setFilterState.bind(this);
    // this.setSurvey = this.setSurvey.bind(this);
    this.setPersonaFilterState = this.setPersonaFilterState.bind(this);
    this.setSaveData = this.setSaveData.bind(this);
    this.setWarn = this.setWarn.bind(this);
  };

  /**
   * ブラウザ判定を行う
   * @param {string} userAgent - 使用ブラウザの種類
   * @param {string} version - Internet Explorerのバージョン
   */
  initSession() {
    //使用ブラウザを検索
    let userAgent = window.navigator.userAgent.toLowerCase();
    if(userAgent.indexOf('msie') !== -1 ||
      userAgent.indexOf('trident') !== -1){
      //Internet Explorerのバージョンを調べる(6-10の場合)
      let version = window.navigator.appVersion.toLowerCase();
      if (version.indexOf("msie 6.") !== -1) {
        this.setWarn.bind(this, "お使いのブラウザは動作保証外です。推奨ブラウザをご利用ください。", "red")();
      }
      //Internet Explorer11の場合
      else{
        this.setWarn.bind(this, "IE11をお使いの場合、機能に一部制限があります。推奨ブラウザをご利用ください。", "red")();
      }
    } else {
      this.setWarn.bind(this, "お使いのブラウザは動作保証外です。推奨ブラウザをご利用ください。", "red")();
    }
  }

  /**
   * Internet Explorer使用の場合、警告文を表示する
   * @param {string} text - 警告文
   * @param {string} color - 警告文の色
   */
  setWarn(text, color){
    this.setState({
      warnText: text,
      warnColor: color
    });
  }

  /**
   * トップページで行った絞り込みのリクエストを更新する
   * @param {object} state - filterAPIへのリクエスト
   */
  setFilterState(state){
    this.setState({listOfFilterInTop: state});
  }

  
  // /**
  //  * 表示中の調査年度をセットする
  //  * @param {object} state - 調査年度
  //  */
  //  setSurvey(state){
  //   this.setState({survey: state});
  // }

  /**
   * ペルソナ詳細ページで行った絞り込みのリクエストを更新する
   * @param {object} state - filterAPIへのリクエスト
   */
  setPersonaFilterState(state){
    this.setState({listOfFilterInPersona: state});
  }

  /**
   * トップページ条件一覧モーダルで再絞り込みを行った絞り込みのリクエストを更新する
   * @param {object} state - filterAPIへのリクエスト
   */
  setReFilterAllState(state){
    this.setState({listOfReFilterInTop: state});
  }

  /**
   * ペルソナ詳細ページ条件一覧モーダルで再絞り込みを行った絞り込みのリクエストを更新する
   * @param {object} state - filterAPIへのリクエスト
   */
  setRePersonaFilterAllState(state){
    this.setState({listOfReFilterInPersona: state});
  }

  /**
   * ユーザが保存した絞り込み条件を更新する
   * @param {object} data - ユーザが保存した絞り込み条件
   */
  setSaveData(data){
    this.setState({listOfFilterDataSavedByUser: data});
  }

  /**
   * (React組み込み関数)画面描画時に使用ブラウザを判定する
   * https://ja.reactjs.org/docs/react-component.html#componentdidmount
   */
  componentDidMount() {   
    this.initSession();
  }

  /**
   * ユーザが開いているページの種類を更新する
   * @param {string} page - ユーザが表示しているページの種類
   */
  setPage(page){
    this.setState({page: page});
  }

  /**
   * 実際にウィンドウに表示する
   * @param {string} sessionId - セッションid
   * @return {JSX} 基本属性・絞り込みグラフモーダル表示用HTMLを返す
   */
  render() {
    const sessionId = sessionStorage.getItem("sessionId");
    return (
      <div>
        <Router key="app_router">
          <Switch key="switch_login">
            <Route
              key="route_login"
              path="/login"
              render={() => 
                <Login 
                  warning={this.state.warnText}
                  color={this.state.warnColor}
                />
              }
            />
            <Auth sessionId={sessionId}>
              <Navi 
                page={this.state.page}
                listOfFilterInTop={this.state.listOfFilterInTop} 
                listOfFilterInPersona={this.state.listOfFilterInPersona} 
                setReFilterAllState={this.setReFilterAllState.bind(this)} 
                setPage={this.setPage.bind(this)} 
                setRePersonaFilterAllState={this.setRePersonaFilterAllState.bind(this)} 
                setSaveData={this.setSaveData.bind(this)}
                // survey={this.state.survey} 
                />
              <Switch key="switch_top">
                <Route 
                  exact path="/" 
                  key="route_top"
                  render={() => 
                    <Top 
                      setFilterState={this.setFilterState.bind(this)} 
                      // setSurvey={this.setSurvey.bind(this)} 
                      listOfReFilterInTop={this.state.listOfReFilterInTop} 
                      setPage={this.setPage.bind(this)}
                    />
                  }
                />
                <Route 
                  key="route_mypage"
                  path="/mypage" 
                  render={() => 
                    <MyPage 
                      listOfFilterDataSavedByUser={this.state.listOfFilterDataSavedByUser} 
                      setPage={this.setPage.bind(this)}
                    />
                  }
                />
                <Route 
                 key="route_detail"
                 path="/persona-detail"
                 render={() => 
                  <PersonaDetail 
                    setPersonaFilterState={this.setPersonaFilterState.bind(this)} 
                    listOfReFilterInPersona={this.state.listOfReFilterInPersona} 
                    // setSurvey={this.setSurvey.bind(this)} 
                    setPage={this.setPage.bind(this)}
                  />
                 }
                />
                <Route 
                  path='/map' 
                  key="route_map"
                  render={() => 
                  <Map 
                    listOfFilterInTop={this.state.listOfFilterInTop}
                    setPage={this.setPage.bind(this)}
                  />
                }
              />
              </Switch>
            </Auth>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;

